@use 'tokens';
@use 'utils';

$focus-ring-color: tokens.$color-focus-ring;
$focus-ring-offset: utils.rem(2);
$focus-ring-width: utils.rem(2);

@mixin focus-ring {
  outline: $focus-ring-width dotted $focus-ring-color;
  outline-offset: $focus-ring-offset;
}

@mixin focus-ring-tab {
  border: $focus-ring-width dotted $focus-ring-color;
  z-index: tokens.$zindex-tab;
}

@mixin hide-visually {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
