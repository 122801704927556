@use '~@woolworthslimited/core-tokens/dist/core/scss/tokens.vars' as core;
@use '~@woolworthslimited/core-tokens/dist/bigw/scss/tokens.vars' as bigw;
@use '~@woolworthslimited/core-tokens/dist/everyday/scss/tokens.vars' as everyday;
@use '~@woolworthslimited/core-tokens/dist/woolworths/scss/tokens.vars' as wow;
@use 'tokens';
@use 'mixins';
@use 'utils';

// TODO: All references of `// [*]` should get removed in favour of using the
// custom themes. However, until we define the themes correctly, we have to
// hardcode like this.

// NOTE: Increase specificity so our styles always get applied.
html {
  .mat-ink-bar,
  .mat-tab-label,
  .mat-tab-link {
    --color: #{core.$color-interface-text-strong}; // [*]
  }

  .mat-tab-label,
  .mat-tab-link {
    color: var(--color) !important; // [*]
    font-family: core.$font-family-roboto;
    font-size: core.$font-body-md-regular-size;
    font-weight: core.$font-weight-regular;
    height: utils.rem(56);
    letter-spacing: utils.rem(0.1);
    min-width: auto;
    opacity: 1;

    &:focus-visible {
      background-color: core.$color-action-background-secondary-focus !important; // [*]

      &::before {
        @include mixins.focus-ring-tab;

        content: '';
        height: 100%;
        position: absolute;
        width: 100%;
      }
    }

    &:hover {
      background-color: core.$color-action-background-secondary-focus !important; // [*]
    }
  }

  .mat-ink-bar {
    background-color: var(--color) !important;
    height: utils.rem(4);
  }

  .mat-tab-label-active,
  .mat-tab-link-active {
    --color: #{core.$color-interface-text-strong}; // [*]

    font-weight: #{core.$font-weight-bold};
  }

  .woolworths-theme {
    .mat-ink-bar {
      --color: #{wow.$color-action-background-primary-default}; // [*]
    }
  }

  .everyday-theme {
    .mat-ink-bar {
      --color: #{everyday.$color-action-background-primary-default}; // [*]
    }
  }

  .bigw-theme {
    .mat-ink-bar {
      --color: #{bigw.$color-action-background-primary-default}; // [*]
    }
  }
}
