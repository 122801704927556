@use '~@woolworthslimited/core-tokens/dist/core/scss/tokens.vars' as core;
@use 'tokens';

.code {
  background-color: core.$color-extended-berry-10;
  display: inline-block;
  font-family: core.$font-family-monaco;
  font-size: core.$font-body-md-medium-size;
  line-height: core.$font-body-md-medium-line-height;
  padding-left: tokens.$space-2xs;
  padding-right: tokens.$space-2xs;
}
