@use '~@woolworthslimited/core-tokens/dist/bigw/scss/tokens.vars' as bigw;
@use '~@woolworthslimited/core-tokens/dist/core/scss/tokens.vars' as core;
@use '~@woolworthslimited/core-tokens/dist/everyday/scss/tokens.vars' as everyday;
@use '~@woolworthslimited/core-tokens/dist/woolworths/scss/tokens.vars' as wow;

:root {
  --bigw: #{bigw.$color-interface-text-highlight};
  --core: #{core.$color-interface-background-highlight-medium};
  --everyday: #{everyday.$color-interface-text-highlight};
  --woolworths: #{wow.$color-interface-background-highlight-medium};

  /* Installation Component */
  --bigw-installation: #{bigw.$color-extended-cobalt-40};
  --core-installation: #{core.$color-action-background-primary-default};
  --everyday-installation: #{everyday.$color-extended-orange-30};
  --woolworths-installation: #{wow.$color-extended-mid-green-20};
}
