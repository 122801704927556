@use '~@woolworthslimited/core-tokens/dist/core/scss/tokens.vars' as core;
@use 'utils';
@use 'shared';
@use 'sass:map';
@use 'sass:color';

/**
 * Typography.
 */
$type-font-family-default: core.$font-family-roboto;
$type-font-family-heading: core.$font-family-fresh-sans;
$type-max-line-length: 80ch;

/**
 * Breakpoints.
 */
$breakpoints: (
  'xxs': core.$breakpoint-xxs,
  'xs': core.$breakpoint-xs,
  'sm': core.$breakpoint-sm,
  'md': core.$breakpoint-md,
  'lg': core.$breakpoint-lg,
  'xl': core.$breakpoint-xl,
  'xxl': core.$breakpoint-xxl,
);

// Semantics
$color-type-default: core.$color-interface-text-medium;
$color-type-heading: core.$color-interface-text-strong;
$color-focus-ring: #000;

/**
 * Spacing (increments of 4).
 */
$space-10xl: utils.rem(64);
$space-9xl: utils.rem(60);
$space-8xl: utils.rem(56);
$space-7xl: utils.rem(52);
$space-6xl: utils.rem(48);
$space-5xl: utils.rem(44);
$space-4xl: utils.rem(40);
$space-3xl: utils.rem(36);
$space-2xl: utils.rem(32);
$space-xl: utils.rem(28);
$space-lg: utils.rem(24);
$space-md: utils.rem(20);
$space-default: utils.rem(16);
$space-sm: utils.rem(12);
$space-xs: utils.rem(8);
$space-2xs: utils.rem(4);
$space-3xs: utils.rem(2);

// Semantics
$space-page-title: $space-6xl;

/**
 * Radius.
 */
$radius-3xl: utils.rem(24);
$radius-2xl: utils.rem(20);
$radius-xl: utils.rem(16);
$radius-lg: utils.rem(12);
$radius-md: utils.rem(8);
$radius-sm: utils.rem(4);
$radius-xs: utils.rem(2);

/**
 * Icon size
*/
$icon-lg: utils.rem(32);
$icon-sm: utils.rem(20);
$icon-xs: utils.rem(16);

/**
 * Box Shadow.
 */
$box-shadow-xs: utils.rem(-1) utils.rem(1) utils.rem(2) 0
  color.scale(core.$color-action-background-primary-default, $alpha: -96%);
$box-shadow-sm: 0 utils.rem(2) utils.rem(4) utils.rem(-1)
  color.scale(core.$color-action-background-primary-default, $alpha: -75%);
$box-shadow-md: 0 utils.rem(2) utils.rem(10) utils.rem(-2)
  color.scale(core.$color-action-background-primary-default, $alpha: -75%);
$box-shadow-lg: 0 utils.rem(10) utils.rem(40) utils.rem(-12)
  color.scale(core.$color-action-background-primary-default, $alpha: -75%);

/**
 * z-index
 */
// TODO: Material zindex value needs to be configured so the DS token value can start with correct number
$zindex-side-nav: 2;
$zindex-header: 3;
$zindex-tab: 1;
