@use 'tokens';
@use 'theme.bigw' as theme-bigw;
@use 'theme.core' as theme-core;
@use 'theme.everyday' as theme-everyday;
@use 'theme.woolworths' as theme-wow;
@use 'sass:map';

@import '~@angular/material/theming';

@include mat-core; // Load Angular Material theme - import once only.

/** Function to create Angular Material custom theme **/
@function get-mat-custom-theme($primary, $accent, $warn, $background, $foreground) {
  $palette-primary: mat-palette($primary, 50, 100, 200);
  $palette-accent: mat-palette($accent, 50, 100, 200);
  $palette-warn: mat-palette($warn, 200, 50, 100);
  $custom-angular-theme: mat-light-theme($palette-primary, $palette-accent, $palette-warn);

  // Overwrite Material background and foreground
  $custom-angular-theme: map.merge(
    $custom-angular-theme,
    (
      background: $background,
    )
  );
  $custom-angular-theme: map.merge(
    $custom-angular-theme,
    (
      foreground: $foreground,
    )
  );

  @return $custom-angular-theme;
}

$mat-theme-bigw: get-mat-custom-theme(
  theme-bigw.$primary-palette,
  theme-bigw.$accent-palette,
  theme-bigw.$warning-palette,
  theme-bigw.$background-override,
  theme-bigw.$foreground-override
);
$mat-theme-ds-core: get-mat-custom-theme(
  theme-core.$primary-palette,
  theme-core.$accent-palette,
  theme-core.$warning-palette,
  theme-core.$background-override,
  theme-core.$foreground-override
);
$mat-theme-everyday: get-mat-custom-theme(
  theme-everyday.$primary-palette,
  theme-everyday.$accent-palette,
  theme-everyday.$warning-palette,
  theme-everyday.$background-override,
  theme-everyday.$foreground-override
);
$mat-theme-wow: get-mat-custom-theme(
  theme-wow.$primary-palette,
  theme-wow.$accent-palette,
  theme-wow.$warning-palette,
  theme-wow.$background-override,
  theme-wow.$foreground-override
);

@include mat-tabs-theme($mat-theme-ds-core);

.woolworths-theme {
  @include angular-material-color($mat-theme-wow);
}

.everyday-theme {
  @include angular-material-color($mat-theme-everyday);
}

.bigw-theme {
  @include angular-material-color($mat-theme-bigw);
}
