@use '~@woolworthslimited/core-tokens/dist/core/scss/tokens.vars' as core;
@use 'tokens';
@use '../shared';
@use 'include-media' as im;
@use 'sass:map';

$col-count: core.$grid-columns-compact;

@mixin generate-breakpoints($is-grid-item-offset: false) {
  @each $breakpoint in map-keys(tokens.$breakpoints) {
    @include im.media('>=#{$breakpoint}') {
      @if $is-grid-item-offset {
        --grid-column-start: var(--col-start-#{$breakpoint});
      } @else {
        --grid-column-span: var(--cols-#{$breakpoint});
      }
    }
  }
}

@mixin up-to-smallest-breakpoint {
  @include im.media('<=xxs') {
    @content;
  }
}

.grid {
  --col-count: #{core.$grid-columns-compact};
  --col-gap: #{core.$grid-margin-compact};
  --row-gap: #{core.$grid-margin-compact};

  column-gap: var(--col-gap);
  display: grid;
  grid-template-columns: repeat(var(--col-count), 1fr);
  row-gap: var(--row-gap);

  @include im.media('>=#{core.$breakpoint-sm}') {
    --col-count: #{core.$grid-columns-regular};
    --col-gap: #{core.$grid-margin-regular};
    --row-gap: #{core.$grid-margin-regular};
  }

  @include im.media('>=#{core.$breakpoint-xxl}') {
    --col-gap: #{core.$grid-margin-expanded};
  }
}

.#{shared.$selector-name-grid-item} {
  --cols: #{$col-count};

  @include im.media('>=#{core.$breakpoint-sm}') {
    --cols: 12;
  }

  // TODO: Construct this via a loop
  --cols-xxs: var(--cols);
  --cols-xs: var(--cols-xxs);
  --cols-sm: var(--cols-xs);
  --cols-md: var(--cols-sm);
  --cols-lg: var(--cols-md);
  --cols-xl: var(--cols-lg);
  --cols-xxl: var(--cols-xl);
  --grid-column-span: var(--cols);

  grid-column: auto / span var(--grid-column-span, #{$col-count});

  // Stacked until the smallest breakpoint
  @include up-to-smallest-breakpoint {
    --grid-column-span: #{$col-count};
  }

  @include generate-breakpoints;

  &.grid-item-offset {
    --col-start: 1;

    // TODO: Construct this via a loop
    --col-start-xxs: var(--col-start);
    --col-start-xs: var(--col-start-xxs);
    --col-start-sm: var(--col-start-xs);
    --col-start-md: var(--col-start-sm);
    --col-start-lg: var(--col-start-md);
    --col-start-xl: var(--col-start-lg);
    --col-start-xxl: var(--col-start-xl);
    --grid-column-start: var(--col-start);

    grid-column-start: var(--grid-column-start);

    // No offset until the smallest breakpoint
    @include up-to-smallest-breakpoint {
      --grid-column-start: 1;
    }

    @include generate-breakpoints(true);
  }
}
