@use 'tokens';
@use 'layout/mixins' as lm;
@use 'include-media' as im;
@use 'sass:map';

$spaces: (
  '10xl': tokens.$space-10xl,
  '9xl': tokens.$space-9xl,
  '8xl': tokens.$space-8xl,
  '7xl': tokens.$space-7xl,
  '6xl': tokens.$space-6xl,
  '5xl': tokens.$space-5xl,
  '4xl': tokens.$space-4xl,
  '3xl': tokens.$space-3xl,
  '2xl': tokens.$space-2xl,
  'xl': tokens.$space-xl,
  'lg': tokens.$space-lg,
  'md': tokens.$space-md,
  'sm': tokens.$space-sm,
  'xs': tokens.$space-xs,
  '2xs': tokens.$space-2xs,
  '3xs': tokens.$space-3xs,
);

.spacer {
  --margin-bottom: #{tokens.$space-default};

  margin-bottom: var(--margin-bottom);

  @each $space, $value in $spaces {
    &.spacer-size-#{$space} {
      --margin-bottom: #{$value};
    }
  }

  &.spacer-for-nested {
    margin-bottom: 0;

    > *:not(:last-child) {
      margin-bottom: var(--margin-bottom);
    }
  }

  &.spacer-mobile-only {
    @include im.media('>=#{map.get(tokens.$breakpoints, "sm") }') {
      --margin-bottom: 0;
    }
  }
}

.u-responsive-spacer {
  @include lm.foundation-responsive-spacer;
}

.u-responsive-spacer-sm {
  --margin: #{tokens.$space-lg};

  margin-bottom: var(--margin);

  @include im.media('>=#{map.get(tokens.$breakpoints, "sm") }') {
    --margin: #{tokens.$space-2xl};
  }
}

.u-responsive-spacer-md {
  --margin: #{tokens.$space-2xl};

  margin-bottom: var(--margin);

  @include im.media('>=#{map.get(tokens.$breakpoints, "sm") }') {
    --margin: #{tokens.$space-6xl};
  }
}

.u-responsive-spacer-xl {
  --margin: #{tokens.$space-6xl};

  margin-bottom: var(--margin);

  @include im.media('>=#{map.get(tokens.$breakpoints, "sm") }') {
    --margin: #{tokens.$space-10xl};
  }
}
