.core-display-lg {
  font-family: "Fresh Sans", sans-serif;
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 1.071;
}

.core-display-sm {
  font-family: "Fresh Sans", sans-serif;
  font-size: 2.75rem;
  font-weight: 500;
  line-height: 1.09;
}

.core-headline-lg {
  font-family: "Fresh Sans", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.125;
}

@media (min-width: 48rem) {
  .core-headline-lg {
    font-size: 2.375rem;
    line-height: 1.052;
  }
}
.core-headline-md {
  font-family: "Fresh Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.166;
}

@media (min-width: 48rem) {
  .core-headline-md {
    font-size: 1.75rem;
    line-height: 1.142;
  }
}
.core-headline-sm {
  font-family: "Fresh Sans", sans-serif;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.181;
}

@media (min-width: 48rem) {
  .core-headline-sm {
    font-size: 1.5rem;
    line-height: 1.142;
  }
}
.core-headline-light-lg {
  font-family: "Fresh Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.125;
}

@media (min-width: 48rem) {
  .core-headline-light-lg {
    font-size: 2.375rem;
    line-height: 1.052;
  }
}
.core-headline-light-md {
  font-family: "Fresh Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.166;
}

@media (min-width: 48rem) {
  .core-headline-light-md {
    font-size: 1.75rem;
    line-height: 1.142;
  }
}
.core-headline-light-sm {
  font-family: "Fresh Sans", sans-serif;
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.181;
}

@media (min-width: 48rem) {
  .core-headline-light-sm {
    font-size: 1.5rem;
    line-height: 1.166;
  }
}
.core-title-lg {
  font-family: "Fresh Sans", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
}

.core-title-md {
  font-family: "Fresh Sans", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.core-title-sm {
  font-family: "Fresh Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.285;
}

.core-body-lg {
  font-size: 1.125rem;
  line-height: 1.556;
}

.core-body-lg-medium {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.556;
}

.core-body-lg-strong {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.556;
}

.core-body-lg-emphasis {
  font-style: italic;
  font-size: 1.125rem;
  line-height: 1.556;
}

.core-body-md {
  font-size: 1rem;
  line-height: 1.5;
}

.core-body-md-medium {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}

.core-body-md-strong {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.core-body-md-emphasis {
  font-size: 1rem;
  font-style: italic;
  line-height: 1.5;
}

.core-body-sm {
  font-size: 0.875rem;
  line-height: 1.429;
}

.core-body-sm-medium {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.429;
}

.core-body-sm-strong {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.429;
}

.core-body-sm-emphasis {
  font-size: 0.875rem;
  font-style: italic;
  line-height: 1.429;
}

/*# sourceMappingURL=core.css.map */
:root {
  --bigw: #002A78;
  --core: #1971ED;
  --everyday: #D44500;
  --woolworths: #178841;
  /* Installation Component */
  --bigw-installation: #6690DE;
  --core-installation: #0E0D26;
  --everyday-installation: #FE924C;
  --woolworths-installation: #74B88D;
}

@font-face {
  font-family: "Fresh Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../resources/fonts/FreshSans/FreshSans-Light.woff2") format("woff2"), url("../resources/fonts/FreshSans/FreshSans-Light.woff") format("woff"), url("../resources/fonts/FreshSans/FreshSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Fresh Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../resources/fonts/FreshSans/FreshSans-LightItalic.woff2") format("woff2"), url("../resources/fonts/FreshSans/FreshSans-LightItalic.woff") format("woff"), url("../resources/fonts/FreshSans/FreshSans-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Fresh Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../resources/fonts/FreshSans/FreshSans-Medium.woff2") format("woff2"), url("../resources/fonts/FreshSans/FreshSans-Medium.woff") format("woff"), url("../resources/fonts/FreshSans/FreshSans-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Fresh Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../resources/fonts/FreshSans/FreshSans-Regular.woff2") format("woff2"), url("../resources/fonts/FreshSans/FreshSans-Regular.woff") format("woff"), url("../resources/fonts/FreshSans/FreshSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Fresh Sans";
  font-style: italic;
  font-weight: normal;
  src: url("../resources/fonts/FreshSans/FreshSans-RegularItalic.woff2") format("woff2"), url("../resources/fonts/FreshSans/FreshSans-RegularItalic.woff") format("woff"), url("../resources/fonts/FreshSans/FreshSans-RegularItalic.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url("../resources/fonts/Roboto/Roboto-Regular-400.woff2") format("woff2"), url("../resources/fonts/Roboto/Roboto-Regular-400.woff") format("woff"), url("../resources/fonts/Roboto/Roboto-Regular-400.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  src: url("../resources/fonts/Roboto/Roboto-RegularItalic-400.woff2") format("woff2"), url("../resources/fonts/Roboto/Roboto-RegularItalic-400.woff") format("woff"), url("../resources/fonts/Roboto/Roboto-RegularItalic-400.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url("../resources/fonts/Roboto/Roboto-Medium-500.woff2") format("woff2"), url("../resources/fonts/Roboto/Roboto-Medium-500.woff") format("woff"), url("../resources/fonts/Roboto/Roboto-Medium-500.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  src: url("../resources/fonts/Roboto/Roboto-MediumItalic-500.woff2") format("woff2"), url("../resources/fonts/Roboto/Roboto-MediumItalic-500.woff") format("woff"), url("../resources/fonts/Roboto/Roboto-MediumItalic-500.ttf") format("truetype");
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url("../resources/fonts/Roboto/Roboto-Bold-700.woff2") format("woff2"), url("../resources/fonts/Roboto/Roboto-Bold-700.woff") format("woff"), url("../resources/fonts/Roboto/Roboto-Bold-700.ttf") format("truetype");
}
@font-face {
  font-family: "GT Big Walsheim";
  font-style: normal;
  font-weight: 400;
  src: url("../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Regular.woff2") format("woff2"), url("../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Regular.woff") format("woff"), url("../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "GT Big Walsheim";
  font-style: normal;
  font-weight: 300;
  src: url("../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Thin.woff2") format("woff2"), url("../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Thin.woff") format("woff"), url("../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "GT Big Walsheim";
  font-style: normal;
  font-weight: 700;
  src: url("../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Bold.woff2") format("woff2"), url("../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Bold.woff") format("woff"), url("../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Bold.ttf") format("truetype");
}
@font-face {
  font-family: BigDays;
  font-style: normal;
  font-weight: 400;
  src: url("../resources/fonts/BigDays/BigDays-Regular.woff2") format("woff2"), url("../resources/fonts/BigDays/BigDays-Regular.woff") format("woff");
}
/* 
  @name _set-props
  @param {object} $set - key and value pair

  @output Populate css property and value

  Description: Private mixin
*/
/* 
  @name set-responsive-style
  @param {String} $key 
  @param {Object | string} $val
  @param {string} $bp - breakpoint on which the font resizes

  @output Populate responsive atrribute of typography if exist, else print the typography property directly

  Description: Private mixin
*/
/* 
  @name build-typography-css
  @param {object} $styles - typography styles

  @output A set of responsive typography styles

  Description: Private mixin
*/
/* 
  @name Power function
  @param {Number} $x - number
  @param {Number} $n - exponential

  @return {Number} - value in power
*/
/* 
  @name to-fixed function
  @param {Number} $float - value to convert

  @return {Number} - fixed value to 3 decimal
*/
/* 
  @name breakpoint
  @param {Number} $px - unitless width

  @return {Number} - breakpoint in em

  Description: https://cloudfour.com/thinks/the-ems-have-it-proportional-media-queries-ftw/
*/
/* 
  @name Calculate line height
  @param {Number} $line-height - unitless line height
  @param {Number} $font-size - unitless font-size

  @return {Number} - Unitless line height
*/
/* 
 @name  rem function
  @param {Number} $px - px value to convert to rem

  @return {Number} - value in rem
*/
/*
  @name: set-hr 

  @param {String}: optional - type of break (solid, dotted etc)

  @output Render thematic break

  Description: Public mixin to set thematic break
*/
/* 
  @name show-type
  @param {String} $type: typography name to query
  @param {Object} $styles: a set of typography map

  @output Render queried typography style including responsive attribute

  Description: Private mixin
*/
/* 
  @name setup-type
  @param {Object} $default-font-styles 
  @param {Object} $font-styles, the collective font styles of each brand

  @output Set default typography attribute

  Description: Private mixin
*/
/*
  @name render-type
  @param {String} $type: typography name to query

  @output Render queried typography style including responsive attribute

  Description: Public mixin
*/
/*
  @name setup-type

  @output Render global typography settings

  Description: Public mixin
*/
/*
  @name: set-hr 

  @param {String}: optional - type of break (solid, dotted etc)

  @output Render thematic break

  Description: Public mixin to set thematic break
*/
/* stylelint-disable scss/at-function-pattern */
/* stylelint-enable */
/**
 * Typography.
 */
/**
 * Breakpoints.
 */
/**
 * Spacing (increments of 4).
 */
/**
 * Radius.
 */
/**
 * Icon size
*/
/**
 * Box Shadow.
 */
/**
 * z-index
 */
html {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #565667;
  scroll-behavior: smooth;
  /* stylelint-disable property-no-vendor-prefix */
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  /* stylelint-enable */
  text-size-adjust: none;
}
html h1 {
  font-family: "Fresh Sans", sans-serif;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.125;
}
@media (min-width: 48rem) {
  html h1 {
    font-size: 2.375rem;
    line-height: 1.052;
  }
}
html h2 {
  font-family: "Fresh Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.166;
}
@media (min-width: 48rem) {
  html h2 {
    font-size: 1.75rem;
    line-height: 1.142;
  }
}
html h3 {
  font-family: "Fresh Sans", sans-serif;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.181;
}
@media (min-width: 48rem) {
  html h3 {
    font-size: 1.5rem;
    line-height: 1.142;
  }
}
html h4 {
  font-family: "Fresh Sans", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
}
html h5 {
  font-family: "Fresh Sans", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
}
html h6 {
  font-family: "Fresh Sans", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.285;
}
html em {
  font-style: italic;
}
html strong {
  font-weight: 700;
}

iframe {
  border: none;
  height: 100vh;
  width: 100%;
}

body {
  background-color: #FFFFFF;
  overflow-x: hidden;
  position: relative;
}

/*
 * Apply all global styles to headings (further extended in
 * 'typography/_headings.scss).
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0E0D26;
}

/*
 * Reinstate correct pointer from "The New CSS Reset".
 */
a,
button {
  cursor: pointer;
}

/*
 * Apply a more readable underline for links and set underline.
 */
a {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

/*
 * Remove underline from navigational links.
 */
nav a {
  text-decoration: none;
}

/*
 * Apply a readable line length to all paragraphs.
 */
p {
  max-width: 80ch;
}

/**
 * Avoid 300ms click delay on touch devices that support the `touch-action` CSS
 * property.
 *
 * @credit
 * - http://caniuse.com/#feat=css-touch-action
 * - http://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay
 * - https://adactio.com/journal/10019
 * - https://developers.google.com/web/updates/2013/12/300ms-tap-delay-gone-away
 */
/* stylelint-disable-next-line no-descending-specificity */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex]:not([tabindex="-1"]) {
  touch-action: manipulation;
}

/*
 * Apply a global focus ring.
 * TODO: Work with design to get focus ring specs
 */
*:focus {
  outline: 0.125rem dotted #000;
  outline-offset: 0.125rem;
}

nav a:focus,
nav button:focus {
  outline: 0.125rem dotted #000;
  outline-offset: 0.125rem;
}

select:focus,
input:focus {
  outline: 0.125rem dotted #000;
  outline-offset: 0.125rem;
}

/*
 * Maintain intrinsic aspect ratios when `max-width` is applied.
 */
img {
  height: auto;
}

/*
 * Remove the default `300px` width, especially as audio files are unlikely to
 * come with a `width` attribute.
 *
 * @credit https://github.com/jensimmons/cssremedy
*/
audio {
  width: 100%;
}

/**
 * Indicates that a label will shift focus to its associated control.
 */
label[for] {
  cursor: pointer;
}

/**
 * Prevents an unwanted focus outline from appearing around elements that might
 * still respond to pointer events.
 */
[tabindex="-1"]:focus {
  outline: none !important;
}

/**
 * Remove the outline in cases where the UA determines via heuristics that the
 * focus should not be visible. This is mainly for ensuring that a focus
 * ring does not render for mouse pointers.
 *
 * NOTE: `!important` is fine here as we always want this to apply.
 */
*:focus:not(:focus-visible) {
  box-shadow: none !important;
  outline: none !important;
}

.container {
  --max-width: none;
  --padding: 1.25rem;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
  padding-left: var(--padding);
  padding-right: var(--padding);
  width: 100%;
}
@media (min-width: 48rem) {
  .container {
    --padding: 2rem;
  }
}
@media (min-width: 105rem) {
  .container {
    --padding: 2.5rem;
  }
}
@media (min-width: 80rem) {
  .container {
    --max-width: 73rem;
  }
}
@media (min-width: 90rem) {
  .container {
    --max-width: 82rem;
  }
}
@media (min-width: 105rem) {
  .container {
    --max-width: 91rem;
  }
}

.grid {
  --col-count: 6;
  --col-gap: 1.25rem;
  --row-gap: 1.25rem;
  column-gap: var(--col-gap);
  display: grid;
  grid-template-columns: repeat(var(--col-count), 1fr);
  row-gap: var(--row-gap);
}
@media (min-width: 48rem) {
  .grid {
    --col-count: 12;
    --col-gap: 2rem;
    --row-gap: 2rem;
  }
}
@media (min-width: 105rem) {
  .grid {
    --col-gap: 2.5rem;
  }
}

.grid-item {
  --cols: 6;
  --cols-xxs: var(--cols);
  --cols-xs: var(--cols-xxs);
  --cols-sm: var(--cols-xs);
  --cols-md: var(--cols-sm);
  --cols-lg: var(--cols-md);
  --cols-xl: var(--cols-lg);
  --cols-xxl: var(--cols-xl);
  --grid-column-span: var(--cols);
  grid-column: auto/span var(--grid-column-span, 6);
}
@media (min-width: 48rem) {
  .grid-item {
    --cols: 12;
  }
}
@media (max-width: 20rem) {
  .grid-item {
    --grid-column-span: 6;
  }
}
@media (min-width: 20rem) {
  .grid-item {
    --grid-column-span: var(--cols-xxs);
  }
}
@media (min-width: 25.875rem) {
  .grid-item {
    --grid-column-span: var(--cols-xs);
  }
}
@media (min-width: 48rem) {
  .grid-item {
    --grid-column-span: var(--cols-sm);
  }
}
@media (min-width: 64rem) {
  .grid-item {
    --grid-column-span: var(--cols-md);
  }
}
@media (min-width: 80rem) {
  .grid-item {
    --grid-column-span: var(--cols-lg);
  }
}
@media (min-width: 90rem) {
  .grid-item {
    --grid-column-span: var(--cols-xl);
  }
}
@media (min-width: 105rem) {
  .grid-item {
    --grid-column-span: var(--cols-xxl);
  }
}
.grid-item.grid-item-offset {
  --col-start: 1;
  --col-start-xxs: var(--col-start);
  --col-start-xs: var(--col-start-xxs);
  --col-start-sm: var(--col-start-xs);
  --col-start-md: var(--col-start-sm);
  --col-start-lg: var(--col-start-md);
  --col-start-xl: var(--col-start-lg);
  --col-start-xxl: var(--col-start-xl);
  --grid-column-start: var(--col-start);
  grid-column-start: var(--grid-column-start);
}
@media (max-width: 20rem) {
  .grid-item.grid-item-offset {
    --grid-column-start: 1;
  }
}
@media (min-width: 20rem) {
  .grid-item.grid-item-offset {
    --grid-column-start: var(--col-start-xxs);
  }
}
@media (min-width: 25.875rem) {
  .grid-item.grid-item-offset {
    --grid-column-start: var(--col-start-xs);
  }
}
@media (min-width: 48rem) {
  .grid-item.grid-item-offset {
    --grid-column-start: var(--col-start-sm);
  }
}
@media (min-width: 64rem) {
  .grid-item.grid-item-offset {
    --grid-column-start: var(--col-start-md);
  }
}
@media (min-width: 80rem) {
  .grid-item.grid-item-offset {
    --grid-column-start: var(--col-start-lg);
  }
}
@media (min-width: 90rem) {
  .grid-item.grid-item-offset {
    --grid-column-start: var(--col-start-xl);
  }
}
@media (min-width: 105rem) {
  .grid-item.grid-item-offset {
    --grid-column-start: var(--col-start-xxl);
  }
}

.spacer {
  --margin-bottom: 1rem;
  margin-bottom: var(--margin-bottom);
}
.spacer.spacer-size-10xl {
  --margin-bottom: 4rem;
}
.spacer.spacer-size-9xl {
  --margin-bottom: 3.75rem;
}
.spacer.spacer-size-8xl {
  --margin-bottom: 3.5rem;
}
.spacer.spacer-size-7xl {
  --margin-bottom: 3.25rem;
}
.spacer.spacer-size-6xl {
  --margin-bottom: 3rem;
}
.spacer.spacer-size-5xl {
  --margin-bottom: 2.75rem;
}
.spacer.spacer-size-4xl {
  --margin-bottom: 2.5rem;
}
.spacer.spacer-size-3xl {
  --margin-bottom: 2.25rem;
}
.spacer.spacer-size-2xl {
  --margin-bottom: 2rem;
}
.spacer.spacer-size-xl {
  --margin-bottom: 1.75rem;
}
.spacer.spacer-size-lg {
  --margin-bottom: 1.5rem;
}
.spacer.spacer-size-md {
  --margin-bottom: 1.25rem;
}
.spacer.spacer-size-sm {
  --margin-bottom: 0.75rem;
}
.spacer.spacer-size-xs {
  --margin-bottom: 0.5rem;
}
.spacer.spacer-size-2xs {
  --margin-bottom: 0.25rem;
}
.spacer.spacer-size-3xs {
  --margin-bottom: 0.125rem;
}
.spacer.spacer-for-nested {
  margin-bottom: 0;
}
.spacer.spacer-for-nested > *:not(:last-child) {
  margin-bottom: var(--margin-bottom);
}
@media (min-width: 48rem) {
  .spacer.spacer-mobile-only {
    --margin-bottom: 0;
  }
}

.u-responsive-spacer {
  --margin: 3.25rem;
  margin-bottom: var(--margin);
}
@media (min-width: 48rem) {
  .u-responsive-spacer {
    --margin: 3.5rem;
  }
}

.u-responsive-spacer-sm {
  --margin: 1.5rem;
  margin-bottom: var(--margin);
}
@media (min-width: 48rem) {
  .u-responsive-spacer-sm {
    --margin: 2rem;
  }
}

.u-responsive-spacer-md {
  --margin: 2rem;
  margin-bottom: var(--margin);
}
@media (min-width: 48rem) {
  .u-responsive-spacer-md {
    --margin: 3rem;
  }
}

.u-responsive-spacer-xl {
  --margin: 3rem;
  margin-bottom: var(--margin);
}
@media (min-width: 48rem) {
  .u-responsive-spacer-xl {
    --margin: 4rem;
  }
}

.code {
  background-color: #F4EAF5;
  display: inline-block;
  font-family: Monaco, Consolas;
  font-size: 1rem;
  line-height: 1.5;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

/** Function to create Angular Material custom theme **/
.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid #dbdbde;
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid #dbdbde;
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: #0e0d26;
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: #dbdbde;
}

.mat-tab-header-pagination-chevron {
  border-color: #0e0d26;
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #dbdbde;
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0e0d26;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #0e0d26;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: #0e0d26;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #0e0d26;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #0e0d26;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #0e0d26;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: #0e0d26;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(14, 13, 38, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: #0e0d26;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #0e0d26;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: #0e0d26;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #0e0d26;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.woolworths-theme .mat-ripple-element {
  background-color: rgba(14, 13, 38, 0.1);
}
.woolworths-theme .mat-option {
  color: #0e0d26;
}
.woolworths-theme .mat-option:hover:not(.mat-option-disabled), .woolworths-theme .mat-option:focus:not(.mat-option-disabled) {
  background: white;
}
.woolworths-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: white;
}
.woolworths-theme .mat-option.mat-active {
  background: white;
  color: #0e0d26;
}
.woolworths-theme .mat-option.mat-option-disabled {
  color: #dbdbde;
}
.woolworths-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #178841;
}
.woolworths-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0e0d26;
}
.woolworths-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0e0d26;
}
.woolworths-theme .mat-optgroup-label {
  color: #0e0d26;
}
.woolworths-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: #dbdbde;
}
.woolworths-theme .mat-pseudo-checkbox {
  color: #0e0d26;
}
.woolworths-theme .mat-pseudo-checkbox::after {
  color: white;
}
.woolworths-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.woolworths-theme .mat-primary .mat-pseudo-checkbox-checked,
.woolworths-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #178841;
}
.woolworths-theme .mat-pseudo-checkbox-checked,
.woolworths-theme .mat-pseudo-checkbox-indeterminate,
.woolworths-theme .mat-accent .mat-pseudo-checkbox-checked,
.woolworths-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #0e0d26;
}
.woolworths-theme .mat-warn .mat-pseudo-checkbox-checked,
.woolworths-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #0e0d26;
}
.woolworths-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.woolworths-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.woolworths-theme .mat-app-background, .woolworths-theme.mat-app-background {
  background-color: white;
  color: #0e0d26;
}
.woolworths-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(14, 13, 38, 0.2), 0px 1px 1px 0px rgba(14, 13, 38, 0.14), 0px 1px 3px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(14, 13, 38, 0.2), 0px 2px 2px 0px rgba(14, 13, 38, 0.14), 0px 1px 5px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(14, 13, 38, 0.2), 0px 3px 4px 0px rgba(14, 13, 38, 0.14), 0px 1px 8px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(14, 13, 38, 0.2), 0px 5px 8px 0px rgba(14, 13, 38, 0.14), 0px 1px 14px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(14, 13, 38, 0.2), 0px 6px 10px 0px rgba(14, 13, 38, 0.14), 0px 1px 18px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(14, 13, 38, 0.2), 0px 7px 10px 1px rgba(14, 13, 38, 0.14), 0px 2px 16px 1px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(14, 13, 38, 0.2), 0px 8px 10px 1px rgba(14, 13, 38, 0.14), 0px 3px 14px 2px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(14, 13, 38, 0.2), 0px 9px 12px 1px rgba(14, 13, 38, 0.14), 0px 3px 16px 2px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(14, 13, 38, 0.2), 0px 10px 14px 1px rgba(14, 13, 38, 0.14), 0px 4px 18px 3px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(14, 13, 38, 0.2), 0px 11px 15px 1px rgba(14, 13, 38, 0.14), 0px 4px 20px 3px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(14, 13, 38, 0.2), 0px 12px 17px 2px rgba(14, 13, 38, 0.14), 0px 5px 22px 4px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(14, 13, 38, 0.2), 0px 13px 19px 2px rgba(14, 13, 38, 0.14), 0px 5px 24px 4px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(14, 13, 38, 0.2), 0px 14px 21px 2px rgba(14, 13, 38, 0.14), 0px 5px 26px 4px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(14, 13, 38, 0.2), 0px 15px 22px 2px rgba(14, 13, 38, 0.14), 0px 6px 28px 5px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(14, 13, 38, 0.2), 0px 16px 24px 2px rgba(14, 13, 38, 0.14), 0px 6px 30px 5px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(14, 13, 38, 0.2), 0px 17px 26px 2px rgba(14, 13, 38, 0.14), 0px 6px 32px 5px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(14, 13, 38, 0.2), 0px 18px 28px 2px rgba(14, 13, 38, 0.14), 0px 7px 34px 6px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(14, 13, 38, 0.2), 0px 19px 29px 2px rgba(14, 13, 38, 0.14), 0px 7px 36px 6px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(14, 13, 38, 0.2), 0px 20px 31px 3px rgba(14, 13, 38, 0.14), 0px 8px 38px 7px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(14, 13, 38, 0.2), 0px 21px 33px 3px rgba(14, 13, 38, 0.14), 0px 8px 40px 7px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(14, 13, 38, 0.2), 0px 22px 35px 3px rgba(14, 13, 38, 0.14), 0px 8px 42px 7px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(14, 13, 38, 0.2), 0px 23px 36px 3px rgba(14, 13, 38, 0.14), 0px 9px 44px 8px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(14, 13, 38, 0.2), 0px 24px 38px 3px rgba(14, 13, 38, 0.14), 0px 9px 46px 8px rgba(14, 13, 38, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.woolworths-theme .mat-autocomplete-panel {
  background: white;
  color: #0e0d26;
}
.woolworths-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.woolworths-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #0e0d26;
}
.woolworths-theme .mat-badge {
  position: relative;
}
.woolworths-theme .mat-badge.mat-badge {
  overflow: visible;
}
.woolworths-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.woolworths-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.woolworths-theme .ng-animate-disabled .mat-badge-content,
.woolworths-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.woolworths-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.woolworths-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.woolworths-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.woolworths-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.woolworths-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .woolworths-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.woolworths-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .woolworths-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.woolworths-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .woolworths-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.woolworths-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .woolworths-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.woolworths-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.woolworths-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.woolworths-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.woolworths-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .woolworths-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.woolworths-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .woolworths-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.woolworths-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .woolworths-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.woolworths-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .woolworths-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.woolworths-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.woolworths-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.woolworths-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.woolworths-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .woolworths-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.woolworths-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .woolworths-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.woolworths-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .woolworths-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.woolworths-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .woolworths-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.woolworths-theme .mat-badge-content {
  color: white;
  background: #178841;
}
.cdk-high-contrast-active .woolworths-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.woolworths-theme .mat-badge-accent .mat-badge-content {
  background: #0e0d26;
  color: #0e0d26;
}
.woolworths-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #0e0d26;
}
.woolworths-theme .mat-badge-disabled .mat-badge-content {
  background: #9f9ea8;
  color: #dbdbde;
}
.woolworths-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(14, 13, 38, 0.2), 0px 16px 24px 2px rgba(14, 13, 38, 0.14), 0px 6px 30px 5px rgba(14, 13, 38, 0.12);
  background: white;
  color: #0e0d26;
}
.woolworths-theme .mat-button, .woolworths-theme .mat-icon-button, .woolworths-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.woolworths-theme .mat-button.mat-primary, .woolworths-theme .mat-icon-button.mat-primary, .woolworths-theme .mat-stroked-button.mat-primary {
  color: #178841;
}
.woolworths-theme .mat-button.mat-accent, .woolworths-theme .mat-icon-button.mat-accent, .woolworths-theme .mat-stroked-button.mat-accent {
  color: #0e0d26;
}
.woolworths-theme .mat-button.mat-warn, .woolworths-theme .mat-icon-button.mat-warn, .woolworths-theme .mat-stroked-button.mat-warn {
  color: #0e0d26;
}
.woolworths-theme .mat-button.mat-primary.mat-button-disabled, .woolworths-theme .mat-button.mat-accent.mat-button-disabled, .woolworths-theme .mat-button.mat-warn.mat-button-disabled, .woolworths-theme .mat-button.mat-button-disabled.mat-button-disabled, .woolworths-theme .mat-icon-button.mat-primary.mat-button-disabled, .woolworths-theme .mat-icon-button.mat-accent.mat-button-disabled, .woolworths-theme .mat-icon-button.mat-warn.mat-button-disabled, .woolworths-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .woolworths-theme .mat-stroked-button.mat-primary.mat-button-disabled, .woolworths-theme .mat-stroked-button.mat-accent.mat-button-disabled, .woolworths-theme .mat-stroked-button.mat-warn.mat-button-disabled, .woolworths-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: #9f9ea8;
}
.woolworths-theme .mat-button.mat-primary .mat-button-focus-overlay, .woolworths-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .woolworths-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #178841;
}
.woolworths-theme .mat-button.mat-accent .mat-button-focus-overlay, .woolworths-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .woolworths-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #0e0d26;
}
.woolworths-theme .mat-button.mat-warn .mat-button-focus-overlay, .woolworths-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .woolworths-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #0e0d26;
}
.woolworths-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .woolworths-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .woolworths-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.woolworths-theme .mat-button .mat-ripple-element, .woolworths-theme .mat-icon-button .mat-ripple-element, .woolworths-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.woolworths-theme .mat-button-focus-overlay {
  background: #0E0D26;
}
.woolworths-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: #dbdbde;
}
.woolworths-theme .mat-flat-button, .woolworths-theme .mat-raised-button, .woolworths-theme .mat-fab, .woolworths-theme .mat-mini-fab {
  color: #0e0d26;
  background-color: white;
}
.woolworths-theme .mat-flat-button.mat-primary, .woolworths-theme .mat-raised-button.mat-primary, .woolworths-theme .mat-fab.mat-primary, .woolworths-theme .mat-mini-fab.mat-primary {
  color: white;
}
.woolworths-theme .mat-flat-button.mat-accent, .woolworths-theme .mat-raised-button.mat-accent, .woolworths-theme .mat-fab.mat-accent, .woolworths-theme .mat-mini-fab.mat-accent {
  color: #0e0d26;
}
.woolworths-theme .mat-flat-button.mat-warn, .woolworths-theme .mat-raised-button.mat-warn, .woolworths-theme .mat-fab.mat-warn, .woolworths-theme .mat-mini-fab.mat-warn {
  color: white;
}
.woolworths-theme .mat-flat-button.mat-primary.mat-button-disabled, .woolworths-theme .mat-flat-button.mat-accent.mat-button-disabled, .woolworths-theme .mat-flat-button.mat-warn.mat-button-disabled, .woolworths-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .woolworths-theme .mat-raised-button.mat-primary.mat-button-disabled, .woolworths-theme .mat-raised-button.mat-accent.mat-button-disabled, .woolworths-theme .mat-raised-button.mat-warn.mat-button-disabled, .woolworths-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .woolworths-theme .mat-fab.mat-primary.mat-button-disabled, .woolworths-theme .mat-fab.mat-accent.mat-button-disabled, .woolworths-theme .mat-fab.mat-warn.mat-button-disabled, .woolworths-theme .mat-fab.mat-button-disabled.mat-button-disabled, .woolworths-theme .mat-mini-fab.mat-primary.mat-button-disabled, .woolworths-theme .mat-mini-fab.mat-accent.mat-button-disabled, .woolworths-theme .mat-mini-fab.mat-warn.mat-button-disabled, .woolworths-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: #9f9ea8;
}
.woolworths-theme .mat-flat-button.mat-primary, .woolworths-theme .mat-raised-button.mat-primary, .woolworths-theme .mat-fab.mat-primary, .woolworths-theme .mat-mini-fab.mat-primary {
  background-color: #178841;
}
.woolworths-theme .mat-flat-button.mat-accent, .woolworths-theme .mat-raised-button.mat-accent, .woolworths-theme .mat-fab.mat-accent, .woolworths-theme .mat-mini-fab.mat-accent {
  background-color: #0e0d26;
}
.woolworths-theme .mat-flat-button.mat-warn, .woolworths-theme .mat-raised-button.mat-warn, .woolworths-theme .mat-fab.mat-warn, .woolworths-theme .mat-mini-fab.mat-warn {
  background-color: #0e0d26;
}
.woolworths-theme .mat-flat-button.mat-primary.mat-button-disabled, .woolworths-theme .mat-flat-button.mat-accent.mat-button-disabled, .woolworths-theme .mat-flat-button.mat-warn.mat-button-disabled, .woolworths-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .woolworths-theme .mat-raised-button.mat-primary.mat-button-disabled, .woolworths-theme .mat-raised-button.mat-accent.mat-button-disabled, .woolworths-theme .mat-raised-button.mat-warn.mat-button-disabled, .woolworths-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .woolworths-theme .mat-fab.mat-primary.mat-button-disabled, .woolworths-theme .mat-fab.mat-accent.mat-button-disabled, .woolworths-theme .mat-fab.mat-warn.mat-button-disabled, .woolworths-theme .mat-fab.mat-button-disabled.mat-button-disabled, .woolworths-theme .mat-mini-fab.mat-primary.mat-button-disabled, .woolworths-theme .mat-mini-fab.mat-accent.mat-button-disabled, .woolworths-theme .mat-mini-fab.mat-warn.mat-button-disabled, .woolworths-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: white;
}
.woolworths-theme .mat-flat-button.mat-primary .mat-ripple-element, .woolworths-theme .mat-raised-button.mat-primary .mat-ripple-element, .woolworths-theme .mat-fab.mat-primary .mat-ripple-element, .woolworths-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.woolworths-theme .mat-flat-button.mat-accent .mat-ripple-element, .woolworths-theme .mat-raised-button.mat-accent .mat-ripple-element, .woolworths-theme .mat-fab.mat-accent .mat-ripple-element, .woolworths-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(14, 13, 38, 0.1);
}
.woolworths-theme .mat-flat-button.mat-warn .mat-ripple-element, .woolworths-theme .mat-raised-button.mat-warn .mat-ripple-element, .woolworths-theme .mat-fab.mat-warn .mat-ripple-element, .woolworths-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.woolworths-theme .mat-stroked-button:not([class*=mat-elevation-z]), .woolworths-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(14, 13, 38, 0.2), 0px 2px 2px 0px rgba(14, 13, 38, 0.14), 0px 1px 5px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(14, 13, 38, 0.2), 0px 8px 10px 1px rgba(14, 13, 38, 0.14), 0px 3px 14px 2px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-fab:not([class*=mat-elevation-z]), .woolworths-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(14, 13, 38, 0.2), 0px 6px 10px 0px rgba(14, 13, 38, 0.14), 0px 1px 18px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .woolworths-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(14, 13, 38, 0.2), 0px 12px 17px 2px rgba(14, 13, 38, 0.14), 0px 5px 22px 4px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .woolworths-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.woolworths-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(14, 13, 38, 0.2), 0px 2px 2px 0px rgba(14, 13, 38, 0.14), 0px 1px 5px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.woolworths-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.woolworths-theme .mat-button-toggle {
  color: #dbdbde;
}
.woolworths-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: white;
}
.woolworths-theme .mat-button-toggle-appearance-standard {
  color: #0e0d26;
  background: white;
}
.woolworths-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.woolworths-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #dbdbde;
}
.woolworths-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #dbdbde;
}
.woolworths-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #dbdbde;
}
.woolworths-theme .mat-button-toggle-checked {
  background-color: white;
  color: #0e0d26;
}
.woolworths-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #0e0d26;
}
.woolworths-theme .mat-button-toggle-disabled {
  color: #9f9ea8;
  background-color: white;
}
.woolworths-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.woolworths-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: white;
}
.woolworths-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.woolworths-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #dbdbde;
}
.woolworths-theme .mat-card {
  background: white;
  color: #0e0d26;
}
.woolworths-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(14, 13, 38, 0.2), 0px 1px 1px 0px rgba(14, 13, 38, 0.14), 0px 1px 3px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-card-subtitle {
  color: #0e0d26;
}
.woolworths-theme .mat-checkbox-frame {
  border-color: #0e0d26;
}
.woolworths-theme .mat-checkbox-checkmark {
  fill: white;
}
.woolworths-theme .mat-checkbox-checkmark-path {
  stroke: white !important;
}
.woolworths-theme .mat-checkbox-mixedmark {
  background-color: white;
}
.woolworths-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .woolworths-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #178841;
}
.woolworths-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .woolworths-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0e0d26;
}
.woolworths-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .woolworths-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #0e0d26;
}
.woolworths-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .woolworths-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.woolworths-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.woolworths-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: #dbdbde;
}
.woolworths-theme .mat-checkbox .mat-ripple-element {
  background-color: #0E0D26;
}
.woolworths-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.woolworths-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #178841;
}
.woolworths-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.woolworths-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #0e0d26;
}
.woolworths-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.woolworths-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #0e0d26;
}
.woolworths-theme .mat-chip.mat-standard-chip {
  background-color: white;
  color: #0e0d26;
}
.woolworths-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #0e0d26;
  opacity: 0.4;
}
.woolworths-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(14, 13, 38, 0.2), 0px 3px 4px 0px rgba(14, 13, 38, 0.14), 0px 1px 8px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.woolworths-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.woolworths-theme .mat-chip.mat-standard-chip::after {
  background: #0E0D26;
}
.woolworths-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #178841;
  color: white;
}
.woolworths-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.woolworths-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.woolworths-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #0e0d26;
  color: white;
}
.woolworths-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.woolworths-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.woolworths-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #0e0d26;
  color: #0e0d26;
}
.woolworths-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: #0e0d26;
  opacity: 0.4;
}
.woolworths-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(14, 13, 38, 0.1);
}
.woolworths-theme .mat-table {
  background: white;
}
.woolworths-theme .mat-table thead, .woolworths-theme .mat-table tbody, .woolworths-theme .mat-table tfoot,
.woolworths-theme mat-header-row, .woolworths-theme mat-row, .woolworths-theme mat-footer-row,
.woolworths-theme [mat-header-row], .woolworths-theme [mat-row], .woolworths-theme [mat-footer-row],
.woolworths-theme .mat-table-sticky {
  background: inherit;
}
.woolworths-theme mat-row, .woolworths-theme mat-header-row, .woolworths-theme mat-footer-row,
.woolworths-theme th.mat-header-cell, .woolworths-theme td.mat-cell, .woolworths-theme td.mat-footer-cell {
  border-bottom-color: #dbdbde;
}
.woolworths-theme .mat-header-cell {
  color: #0e0d26;
}
.woolworths-theme .mat-cell, .woolworths-theme .mat-footer-cell {
  color: #0e0d26;
}
.woolworths-theme .mat-calendar-arrow {
  fill: #0e0d26;
}
.woolworths-theme .mat-datepicker-toggle,
.woolworths-theme .mat-datepicker-content .mat-calendar-next-button,
.woolworths-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: #0e0d26;
}
.woolworths-theme .mat-calendar-table-header-divider::after {
  background: #dbdbde;
}
.woolworths-theme .mat-calendar-table-header,
.woolworths-theme .mat-calendar-body-label {
  color: #0e0d26;
}
.woolworths-theme .mat-calendar-body-cell-content,
.woolworths-theme .mat-date-range-input-separator {
  color: #0e0d26;
  border-color: transparent;
}
.woolworths-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #dbdbde;
}
.woolworths-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: #dbdbde;
}
.woolworths-theme .mat-calendar-body-in-preview {
  color: #dbdbde;
}
.woolworths-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #dbdbde;
}
.woolworths-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(219, 219, 222, 0.8);
}
.woolworths-theme .mat-calendar-body-in-range::before {
  background: rgba(23, 136, 65, 0.2);
}
.woolworths-theme .mat-calendar-body-comparison-identical,
.woolworths-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.woolworths-theme .mat-calendar-body-comparison-bridge-start::before,
.woolworths-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(23, 136, 65, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.woolworths-theme .mat-calendar-body-comparison-bridge-end::before,
.woolworths-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(23, 136, 65, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.woolworths-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.woolworths-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.woolworths-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.woolworths-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.woolworths-theme .mat-calendar-body-selected {
  background-color: #178841;
  color: white;
}
.woolworths-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(23, 136, 65, 0.4);
}
.woolworths-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.woolworths-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.woolworths-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(23, 136, 65, 0.3);
}
@media (hover: hover) {
  .woolworths-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(23, 136, 65, 0.3);
  }
}
.woolworths-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
  background-color: white;
  color: #0e0d26;
}
.woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(14, 13, 38, 0.2);
}
.woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.woolworths-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(14, 13, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.woolworths-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(14, 13, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #0e0d26;
  color: #0e0d26;
}
.woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(14, 13, 38, 0.4);
}
.woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #0e0d26;
}
.woolworths-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.woolworths-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(14, 13, 38, 0.3);
}
@media (hover: hover) {
  .woolworths-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(14, 13, 38, 0.3);
  }
}
.woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(14, 13, 38, 0.2);
}
.woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.woolworths-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(14, 13, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.woolworths-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(14, 13, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #0e0d26;
  color: white;
}
.woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(14, 13, 38, 0.4);
}
.woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.woolworths-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.woolworths-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(14, 13, 38, 0.3);
}
@media (hover: hover) {
  .woolworths-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(14, 13, 38, 0.3);
  }
}
.woolworths-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(14, 13, 38, 0.2), 0px 24px 38px 3px rgba(14, 13, 38, 0.14), 0px 9px 46px 8px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-datepicker-toggle-active {
  color: #178841;
}
.woolworths-theme .mat-datepicker-toggle-active.mat-accent {
  color: #0e0d26;
}
.woolworths-theme .mat-datepicker-toggle-active.mat-warn {
  color: #0e0d26;
}
.woolworths-theme .mat-date-range-input-inner[disabled] {
  color: #dbdbde;
}
.woolworths-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(14, 13, 38, 0.2), 0px 24px 38px 3px rgba(14, 13, 38, 0.14), 0px 9px 46px 8px rgba(14, 13, 38, 0.12);
  background: white;
  color: #0e0d26;
}
.woolworths-theme .mat-divider {
  border-top-color: #dbdbde;
}
.woolworths-theme .mat-divider-vertical {
  border-right-color: #dbdbde;
}
.woolworths-theme .mat-expansion-panel {
  background: white;
  color: #0e0d26;
}
.woolworths-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(14, 13, 38, 0.2), 0px 2px 2px 0px rgba(14, 13, 38, 0.14), 0px 1px 5px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-action-row {
  border-top-color: #dbdbde;
}
.woolworths-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .woolworths-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .woolworths-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: white;
}
@media (hover: none) {
  .woolworths-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.woolworths-theme .mat-expansion-panel-header-title {
  color: #0e0d26;
}
.woolworths-theme .mat-expansion-panel-header-description,
.woolworths-theme .mat-expansion-indicator::after {
  color: #0e0d26;
}
.woolworths-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: #9f9ea8;
}
.woolworths-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.woolworths-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.woolworths-theme .mat-form-field-label {
  color: rgba(14, 13, 38, 0.6);
}
.woolworths-theme .mat-hint {
  color: rgba(14, 13, 38, 0.6);
}
.woolworths-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #178841;
}
.woolworths-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #0e0d26;
}
.woolworths-theme .mat-focused .mat-form-field-required-marker {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field-ripple {
  background-color: rgba(219, 219, 222, 0.87);
}
.woolworths-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #178841;
}
.woolworths-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #0e0d26;
}
.woolworths-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #0e0d26;
}
.woolworths-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #178841;
}
.woolworths-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.woolworths-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.woolworths-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #0e0d26;
}
.woolworths-theme .mat-error {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field-appearance-legacy .mat-hint {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(219, 219, 222, 0.42);
}
.woolworths-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(219, 219, 222, 0.42) 0%, rgba(219, 219, 222, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.woolworths-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(219, 219, 222, 0.42);
}
.woolworths-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(219, 219, 222, 0.42) 0%, rgba(219, 219, 222, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.woolworths-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(14, 13, 38, 0.04);
}
.woolworths-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(14, 13, 38, 0.02);
}
.woolworths-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(219, 219, 222, 0.42);
}
.woolworths-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: #dbdbde;
}
.woolworths-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.woolworths-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(219, 219, 222, 0.12);
}
.woolworths-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(219, 219, 222, 0.87);
}
.woolworths-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #178841;
}
.woolworths-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #dbdbde;
}
.woolworths-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(219, 219, 222, 0.06);
}
.woolworths-theme .mat-icon.mat-primary {
  color: #178841;
}
.woolworths-theme .mat-icon.mat-accent {
  color: #0e0d26;
}
.woolworths-theme .mat-icon.mat-warn {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #0e0d26;
}
.woolworths-theme .mat-input-element:disabled,
.woolworths-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: #dbdbde;
}
.woolworths-theme .mat-input-element {
  caret-color: #178841;
}
.woolworths-theme .mat-input-element::placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.woolworths-theme .mat-input-element::-moz-placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.woolworths-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.woolworths-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.woolworths-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #0e0d26;
}
.woolworths-theme .mat-form-field.mat-warn .mat-input-element,
.woolworths-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #0e0d26;
}
.woolworths-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #0e0d26;
}
.woolworths-theme .mat-list-base .mat-list-item {
  color: #0e0d26;
}
.woolworths-theme .mat-list-base .mat-list-option {
  color: #0e0d26;
}
.woolworths-theme .mat-list-base .mat-subheader {
  color: #0e0d26;
}
.woolworths-theme .mat-list-base .mat-list-item-disabled {
  background-color: white;
  color: #dbdbde;
}
.woolworths-theme .mat-list-option:hover, .woolworths-theme .mat-list-option:focus,
.woolworths-theme .mat-nav-list .mat-list-item:hover,
.woolworths-theme .mat-nav-list .mat-list-item:focus,
.woolworths-theme .mat-action-list .mat-list-item:hover,
.woolworths-theme .mat-action-list .mat-list-item:focus {
  background: white;
}
.woolworths-theme .mat-list-single-selected-option, .woolworths-theme .mat-list-single-selected-option:hover, .woolworths-theme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.woolworths-theme .mat-menu-panel {
  background: white;
}
.woolworths-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-menu-item {
  background: transparent;
  color: #0e0d26;
}
.woolworths-theme .mat-menu-item[disabled],
.woolworths-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.woolworths-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: #dbdbde;
}
.woolworths-theme .mat-menu-item .mat-icon-no-color,
.woolworths-theme .mat-menu-submenu-icon {
  color: #0e0d26;
}
.woolworths-theme .mat-menu-item:hover:not([disabled]),
.woolworths-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.woolworths-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.woolworths-theme .mat-menu-item-highlighted:not([disabled]) {
  background: white;
}
.woolworths-theme .mat-paginator {
  background: white;
}
.woolworths-theme .mat-paginator,
.woolworths-theme .mat-paginator-page-size .mat-select-trigger {
  color: #0e0d26;
}
.woolworths-theme .mat-paginator-decrement,
.woolworths-theme .mat-paginator-increment {
  border-top: 2px solid #0e0d26;
  border-right: 2px solid #0e0d26;
}
.woolworths-theme .mat-paginator-first,
.woolworths-theme .mat-paginator-last {
  border-top: 2px solid #0e0d26;
}
.woolworths-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.woolworths-theme .mat-icon-button[disabled] .mat-paginator-increment,
.woolworths-theme .mat-icon-button[disabled] .mat-paginator-first,
.woolworths-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: #dbdbde;
}
.woolworths-theme .mat-progress-bar-background {
  fill: #c5e1d0;
}
.woolworths-theme .mat-progress-bar-buffer {
  background-color: #c5e1d0;
}
.woolworths-theme .mat-progress-bar-fill::after {
  background-color: #178841;
}
.woolworths-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #c3c3c9;
}
.woolworths-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #c3c3c9;
}
.woolworths-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #0e0d26;
}
.woolworths-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #c3c3c9;
}
.woolworths-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #c3c3c9;
}
.woolworths-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #0e0d26;
}
.woolworths-theme .mat-progress-spinner circle, .woolworths-theme .mat-spinner circle {
  stroke: #178841;
}
.woolworths-theme .mat-progress-spinner.mat-accent circle, .woolworths-theme .mat-spinner.mat-accent circle {
  stroke: #0e0d26;
}
.woolworths-theme .mat-progress-spinner.mat-warn circle, .woolworths-theme .mat-spinner.mat-warn circle {
  stroke: #0e0d26;
}
.woolworths-theme .mat-radio-outer-circle {
  border-color: #0e0d26;
}
.woolworths-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #178841;
}
.woolworths-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.woolworths-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .woolworths-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .woolworths-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #178841;
}
.woolworths-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0e0d26;
}
.woolworths-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.woolworths-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .woolworths-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .woolworths-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #0e0d26;
}
.woolworths-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0e0d26;
}
.woolworths-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.woolworths-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .woolworths-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .woolworths-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #0e0d26;
}
.woolworths-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.woolworths-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: #dbdbde;
}
.woolworths-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.woolworths-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #dbdbde;
}
.woolworths-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: #dbdbde;
}
.woolworths-theme .mat-radio-button .mat-ripple-element {
  background-color: #0E0D26;
}
.woolworths-theme .mat-select-value {
  color: #0e0d26;
}
.woolworths-theme .mat-select-placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.woolworths-theme .mat-select-disabled .mat-select-value {
  color: #dbdbde;
}
.woolworths-theme .mat-select-arrow {
  color: #0e0d26;
}
.woolworths-theme .mat-select-panel {
  background: white;
}
.woolworths-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.woolworths-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #178841;
}
.woolworths-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #0e0d26;
}
.woolworths-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: #dbdbde;
}
.woolworths-theme .mat-drawer-container {
  background-color: white;
  color: #0e0d26;
}
.woolworths-theme .mat-drawer {
  background-color: white;
  color: #0e0d26;
}
.woolworths-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.woolworths-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(14, 13, 38, 0.2), 0px 16px 24px 2px rgba(14, 13, 38, 0.14), 0px 6px 30px 5px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-drawer-side {
  border-right: solid 1px #dbdbde;
}
.woolworths-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px #dbdbde;
  border-right: none;
}
.woolworths-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px #dbdbde;
  border-right: none;
}
.woolworths-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px #dbdbde;
}
.woolworths-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.woolworths-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(14, 13, 38, 0.54);
}
.woolworths-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #178841;
}
.woolworths-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(23, 136, 65, 0.54);
}
.woolworths-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #178841;
}
.woolworths-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(14, 13, 38, 0.54);
}
.woolworths-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(14, 13, 38, 0.2), 0px 1px 1px 0px rgba(14, 13, 38, 0.14), 0px 1px 3px 0px rgba(14, 13, 38, 0.12);
  background-color: #fafafa;
}
.woolworths-theme .mat-slide-toggle-bar {
  background-color: #dbdbde;
}
.woolworths-theme .mat-slider-track-background {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slider.mat-primary .mat-slider-track-fill,
.woolworths-theme .mat-slider.mat-primary .mat-slider-thumb,
.woolworths-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #178841;
}
.woolworths-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.woolworths-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(23, 136, 65, 0.2);
}
.woolworths-theme .mat-slider.mat-accent .mat-slider-track-fill,
.woolworths-theme .mat-slider.mat-accent .mat-slider-thumb,
.woolworths-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: #0e0d26;
}
.woolworths-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(14, 13, 38, 0.2);
}
.woolworths-theme .mat-slider.mat-warn .mat-slider-track-fill,
.woolworths-theme .mat-slider.mat-warn .mat-slider-thumb,
.woolworths-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.woolworths-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(14, 13, 38, 0.2);
}
.woolworths-theme .mat-slider:hover .mat-slider-track-background,
.woolworths-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.woolworths-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.woolworths-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.woolworths-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.woolworths-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: #0e0d26;
}
.woolworths-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: #0e0d26;
  background-color: transparent;
}
.woolworths-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .woolworths-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: #0e0d26;
}
.woolworths-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .woolworths-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: #0e0d26;
}
.woolworths-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(14, 13, 38, 0.7);
}
.woolworths-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(14, 13, 38, 0.7), rgba(14, 13, 38, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(14, 13, 38, 0.7), rgba(14, 13, 38, 0.7) 2px, transparent 0, transparent);
}
.woolworths-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(14, 13, 38, 0.7), rgba(14, 13, 38, 0.7) 2px, transparent 0, transparent);
}
.woolworths-theme .mat-step-header.cdk-keyboard-focused, .woolworths-theme .mat-step-header.cdk-program-focused, .woolworths-theme .mat-step-header:hover:not([aria-disabled]), .woolworths-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: white;
}
.woolworths-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .woolworths-theme .mat-step-header:hover {
    background: none;
  }
}
.woolworths-theme .mat-step-header .mat-step-label,
.woolworths-theme .mat-step-header .mat-step-optional {
  color: #0e0d26;
}
.woolworths-theme .mat-step-header .mat-step-icon {
  background-color: #0e0d26;
  color: white;
}
.woolworths-theme .mat-step-header .mat-step-icon-selected,
.woolworths-theme .mat-step-header .mat-step-icon-state-done,
.woolworths-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #178841;
  color: white;
}
.woolworths-theme .mat-step-header.mat-accent .mat-step-icon {
  color: #0e0d26;
}
.woolworths-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.woolworths-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.woolworths-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #0e0d26;
  color: #0e0d26;
}
.woolworths-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.woolworths-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.woolworths-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.woolworths-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #0e0d26;
  color: white;
}
.woolworths-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #0e0d26;
}
.woolworths-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: #0e0d26;
}
.woolworths-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #0e0d26;
}
.woolworths-theme .mat-stepper-horizontal, .woolworths-theme .mat-stepper-vertical {
  background-color: white;
}
.woolworths-theme .mat-stepper-vertical-line::before {
  border-left-color: #dbdbde;
}
.woolworths-theme .mat-horizontal-stepper-header::before,
.woolworths-theme .mat-horizontal-stepper-header::after,
.woolworths-theme .mat-stepper-horizontal-line {
  border-top-color: #dbdbde;
}
.woolworths-theme .mat-sort-header-arrow {
  color: #0e0d26;
}
.woolworths-theme .mat-tab-nav-bar,
.woolworths-theme .mat-tab-header {
  border-bottom: 1px solid #dbdbde;
}
.woolworths-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.woolworths-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid #dbdbde;
  border-bottom: none;
}
.woolworths-theme .mat-tab-label, .woolworths-theme .mat-tab-link {
  color: #0e0d26;
}
.woolworths-theme .mat-tab-label.mat-tab-disabled, .woolworths-theme .mat-tab-link.mat-tab-disabled {
  color: #dbdbde;
}
.woolworths-theme .mat-tab-header-pagination-chevron {
  border-color: #0e0d26;
}
.woolworths-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #dbdbde;
}
.woolworths-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.woolworths-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.woolworths-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(23, 136, 65, 0.3);
}
.woolworths-theme .mat-tab-group.mat-primary .mat-ink-bar, .woolworths-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #178841;
}
.woolworths-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .woolworths-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .woolworths-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .woolworths-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.woolworths-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.woolworths-theme .mat-tab-group.mat-accent .mat-ink-bar, .woolworths-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #0e0d26;
}
.woolworths-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .woolworths-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .woolworths-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .woolworths-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: #0e0d26;
}
.woolworths-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.woolworths-theme .mat-tab-group.mat-warn .mat-ink-bar, .woolworths-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #0e0d26;
}
.woolworths-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .woolworths-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .woolworths-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .woolworths-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.woolworths-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(23, 136, 65, 0.3);
}
.woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #178841;
}
.woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.woolworths-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.woolworths-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.woolworths-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #0e0d26;
}
.woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: #0e0d26;
}
.woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(14, 13, 38, 0.4);
}
.woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: #0e0d26;
}
.woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #0e0d26;
  opacity: 0.4;
}
.woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.woolworths-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.woolworths-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: #0e0d26;
  opacity: 0.12;
}
.woolworths-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .woolworths-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.woolworths-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #0e0d26;
}
.woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.woolworths-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.woolworths-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.woolworths-theme .mat-toolbar {
  background: white;
  color: #0e0d26;
}
.woolworths-theme .mat-toolbar.mat-primary {
  background: #178841;
  color: white;
}
.woolworths-theme .mat-toolbar.mat-accent {
  background: #0e0d26;
  color: #0e0d26;
}
.woolworths-theme .mat-toolbar.mat-warn {
  background: #0e0d26;
  color: white;
}
.woolworths-theme .mat-toolbar .mat-form-field-underline,
.woolworths-theme .mat-toolbar .mat-form-field-ripple,
.woolworths-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.woolworths-theme .mat-toolbar .mat-form-field-label,
.woolworths-theme .mat-toolbar .mat-focused .mat-form-field-label,
.woolworths-theme .mat-toolbar .mat-select-value,
.woolworths-theme .mat-toolbar .mat-select-arrow,
.woolworths-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.woolworths-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.woolworths-theme .mat-tree {
  background: white;
}
.woolworths-theme .mat-tree-node,
.woolworths-theme .mat-nested-tree-node {
  color: #0e0d26;
}
.woolworths-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(14, 13, 38, 0.2), 0px 6px 10px 0px rgba(14, 13, 38, 0.14), 0px 1px 18px 0px rgba(14, 13, 38, 0.12);
}
.woolworths-theme .mat-simple-snackbar-action {
  color: #0e0d26;
}

.everyday-theme .mat-ripple-element {
  background-color: rgba(14, 13, 38, 0.1);
}
.everyday-theme .mat-option {
  color: #0e0d26;
}
.everyday-theme .mat-option:hover:not(.mat-option-disabled), .everyday-theme .mat-option:focus:not(.mat-option-disabled) {
  background: white;
}
.everyday-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: white;
}
.everyday-theme .mat-option.mat-active {
  background: white;
  color: #0e0d26;
}
.everyday-theme .mat-option.mat-option-disabled {
  color: #dbdbde;
}
.everyday-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #d44500;
}
.everyday-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0e0d26;
}
.everyday-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0e0d26;
}
.everyday-theme .mat-optgroup-label {
  color: #0e0d26;
}
.everyday-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: #dbdbde;
}
.everyday-theme .mat-pseudo-checkbox {
  color: #0e0d26;
}
.everyday-theme .mat-pseudo-checkbox::after {
  color: white;
}
.everyday-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.everyday-theme .mat-primary .mat-pseudo-checkbox-checked,
.everyday-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #d44500;
}
.everyday-theme .mat-pseudo-checkbox-checked,
.everyday-theme .mat-pseudo-checkbox-indeterminate,
.everyday-theme .mat-accent .mat-pseudo-checkbox-checked,
.everyday-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #0e0d26;
}
.everyday-theme .mat-warn .mat-pseudo-checkbox-checked,
.everyday-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #0e0d26;
}
.everyday-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.everyday-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.everyday-theme .mat-app-background, .everyday-theme.mat-app-background {
  background-color: white;
  color: #0e0d26;
}
.everyday-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(14, 13, 38, 0.2), 0px 1px 1px 0px rgba(14, 13, 38, 0.14), 0px 1px 3px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(14, 13, 38, 0.2), 0px 2px 2px 0px rgba(14, 13, 38, 0.14), 0px 1px 5px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(14, 13, 38, 0.2), 0px 3px 4px 0px rgba(14, 13, 38, 0.14), 0px 1px 8px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(14, 13, 38, 0.2), 0px 5px 8px 0px rgba(14, 13, 38, 0.14), 0px 1px 14px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(14, 13, 38, 0.2), 0px 6px 10px 0px rgba(14, 13, 38, 0.14), 0px 1px 18px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(14, 13, 38, 0.2), 0px 7px 10px 1px rgba(14, 13, 38, 0.14), 0px 2px 16px 1px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(14, 13, 38, 0.2), 0px 8px 10px 1px rgba(14, 13, 38, 0.14), 0px 3px 14px 2px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(14, 13, 38, 0.2), 0px 9px 12px 1px rgba(14, 13, 38, 0.14), 0px 3px 16px 2px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(14, 13, 38, 0.2), 0px 10px 14px 1px rgba(14, 13, 38, 0.14), 0px 4px 18px 3px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(14, 13, 38, 0.2), 0px 11px 15px 1px rgba(14, 13, 38, 0.14), 0px 4px 20px 3px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(14, 13, 38, 0.2), 0px 12px 17px 2px rgba(14, 13, 38, 0.14), 0px 5px 22px 4px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(14, 13, 38, 0.2), 0px 13px 19px 2px rgba(14, 13, 38, 0.14), 0px 5px 24px 4px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(14, 13, 38, 0.2), 0px 14px 21px 2px rgba(14, 13, 38, 0.14), 0px 5px 26px 4px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(14, 13, 38, 0.2), 0px 15px 22px 2px rgba(14, 13, 38, 0.14), 0px 6px 28px 5px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(14, 13, 38, 0.2), 0px 16px 24px 2px rgba(14, 13, 38, 0.14), 0px 6px 30px 5px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(14, 13, 38, 0.2), 0px 17px 26px 2px rgba(14, 13, 38, 0.14), 0px 6px 32px 5px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(14, 13, 38, 0.2), 0px 18px 28px 2px rgba(14, 13, 38, 0.14), 0px 7px 34px 6px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(14, 13, 38, 0.2), 0px 19px 29px 2px rgba(14, 13, 38, 0.14), 0px 7px 36px 6px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(14, 13, 38, 0.2), 0px 20px 31px 3px rgba(14, 13, 38, 0.14), 0px 8px 38px 7px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(14, 13, 38, 0.2), 0px 21px 33px 3px rgba(14, 13, 38, 0.14), 0px 8px 40px 7px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(14, 13, 38, 0.2), 0px 22px 35px 3px rgba(14, 13, 38, 0.14), 0px 8px 42px 7px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(14, 13, 38, 0.2), 0px 23px 36px 3px rgba(14, 13, 38, 0.14), 0px 9px 44px 8px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(14, 13, 38, 0.2), 0px 24px 38px 3px rgba(14, 13, 38, 0.14), 0px 9px 46px 8px rgba(14, 13, 38, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.everyday-theme .mat-autocomplete-panel {
  background: white;
  color: #0e0d26;
}
.everyday-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.everyday-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #0e0d26;
}
.everyday-theme .mat-badge {
  position: relative;
}
.everyday-theme .mat-badge.mat-badge {
  overflow: visible;
}
.everyday-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.everyday-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.everyday-theme .ng-animate-disabled .mat-badge-content,
.everyday-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.everyday-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.everyday-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.everyday-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.everyday-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.everyday-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .everyday-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.everyday-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .everyday-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.everyday-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .everyday-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.everyday-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .everyday-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.everyday-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.everyday-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.everyday-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.everyday-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .everyday-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.everyday-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .everyday-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.everyday-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .everyday-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.everyday-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .everyday-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.everyday-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.everyday-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.everyday-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.everyday-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .everyday-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.everyday-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .everyday-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.everyday-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .everyday-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.everyday-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .everyday-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.everyday-theme .mat-badge-content {
  color: white;
  background: #d44500;
}
.cdk-high-contrast-active .everyday-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.everyday-theme .mat-badge-accent .mat-badge-content {
  background: #0e0d26;
  color: #0e0d26;
}
.everyday-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #0e0d26;
}
.everyday-theme .mat-badge-disabled .mat-badge-content {
  background: #9f9ea8;
  color: #dbdbde;
}
.everyday-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(14, 13, 38, 0.2), 0px 16px 24px 2px rgba(14, 13, 38, 0.14), 0px 6px 30px 5px rgba(14, 13, 38, 0.12);
  background: white;
  color: #0e0d26;
}
.everyday-theme .mat-button, .everyday-theme .mat-icon-button, .everyday-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.everyday-theme .mat-button.mat-primary, .everyday-theme .mat-icon-button.mat-primary, .everyday-theme .mat-stroked-button.mat-primary {
  color: #d44500;
}
.everyday-theme .mat-button.mat-accent, .everyday-theme .mat-icon-button.mat-accent, .everyday-theme .mat-stroked-button.mat-accent {
  color: #0e0d26;
}
.everyday-theme .mat-button.mat-warn, .everyday-theme .mat-icon-button.mat-warn, .everyday-theme .mat-stroked-button.mat-warn {
  color: #0e0d26;
}
.everyday-theme .mat-button.mat-primary.mat-button-disabled, .everyday-theme .mat-button.mat-accent.mat-button-disabled, .everyday-theme .mat-button.mat-warn.mat-button-disabled, .everyday-theme .mat-button.mat-button-disabled.mat-button-disabled, .everyday-theme .mat-icon-button.mat-primary.mat-button-disabled, .everyday-theme .mat-icon-button.mat-accent.mat-button-disabled, .everyday-theme .mat-icon-button.mat-warn.mat-button-disabled, .everyday-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .everyday-theme .mat-stroked-button.mat-primary.mat-button-disabled, .everyday-theme .mat-stroked-button.mat-accent.mat-button-disabled, .everyday-theme .mat-stroked-button.mat-warn.mat-button-disabled, .everyday-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: #9f9ea8;
}
.everyday-theme .mat-button.mat-primary .mat-button-focus-overlay, .everyday-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .everyday-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #d44500;
}
.everyday-theme .mat-button.mat-accent .mat-button-focus-overlay, .everyday-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .everyday-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #0e0d26;
}
.everyday-theme .mat-button.mat-warn .mat-button-focus-overlay, .everyday-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .everyday-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #0e0d26;
}
.everyday-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .everyday-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .everyday-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.everyday-theme .mat-button .mat-ripple-element, .everyday-theme .mat-icon-button .mat-ripple-element, .everyday-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.everyday-theme .mat-button-focus-overlay {
  background: #0E0D26;
}
.everyday-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: #dbdbde;
}
.everyday-theme .mat-flat-button, .everyday-theme .mat-raised-button, .everyday-theme .mat-fab, .everyday-theme .mat-mini-fab {
  color: #0e0d26;
  background-color: white;
}
.everyday-theme .mat-flat-button.mat-primary, .everyday-theme .mat-raised-button.mat-primary, .everyday-theme .mat-fab.mat-primary, .everyday-theme .mat-mini-fab.mat-primary {
  color: white;
}
.everyday-theme .mat-flat-button.mat-accent, .everyday-theme .mat-raised-button.mat-accent, .everyday-theme .mat-fab.mat-accent, .everyday-theme .mat-mini-fab.mat-accent {
  color: #0e0d26;
}
.everyday-theme .mat-flat-button.mat-warn, .everyday-theme .mat-raised-button.mat-warn, .everyday-theme .mat-fab.mat-warn, .everyday-theme .mat-mini-fab.mat-warn {
  color: white;
}
.everyday-theme .mat-flat-button.mat-primary.mat-button-disabled, .everyday-theme .mat-flat-button.mat-accent.mat-button-disabled, .everyday-theme .mat-flat-button.mat-warn.mat-button-disabled, .everyday-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .everyday-theme .mat-raised-button.mat-primary.mat-button-disabled, .everyday-theme .mat-raised-button.mat-accent.mat-button-disabled, .everyday-theme .mat-raised-button.mat-warn.mat-button-disabled, .everyday-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .everyday-theme .mat-fab.mat-primary.mat-button-disabled, .everyday-theme .mat-fab.mat-accent.mat-button-disabled, .everyday-theme .mat-fab.mat-warn.mat-button-disabled, .everyday-theme .mat-fab.mat-button-disabled.mat-button-disabled, .everyday-theme .mat-mini-fab.mat-primary.mat-button-disabled, .everyday-theme .mat-mini-fab.mat-accent.mat-button-disabled, .everyday-theme .mat-mini-fab.mat-warn.mat-button-disabled, .everyday-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: #9f9ea8;
}
.everyday-theme .mat-flat-button.mat-primary, .everyday-theme .mat-raised-button.mat-primary, .everyday-theme .mat-fab.mat-primary, .everyday-theme .mat-mini-fab.mat-primary {
  background-color: #d44500;
}
.everyday-theme .mat-flat-button.mat-accent, .everyday-theme .mat-raised-button.mat-accent, .everyday-theme .mat-fab.mat-accent, .everyday-theme .mat-mini-fab.mat-accent {
  background-color: #0e0d26;
}
.everyday-theme .mat-flat-button.mat-warn, .everyday-theme .mat-raised-button.mat-warn, .everyday-theme .mat-fab.mat-warn, .everyday-theme .mat-mini-fab.mat-warn {
  background-color: #0e0d26;
}
.everyday-theme .mat-flat-button.mat-primary.mat-button-disabled, .everyday-theme .mat-flat-button.mat-accent.mat-button-disabled, .everyday-theme .mat-flat-button.mat-warn.mat-button-disabled, .everyday-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .everyday-theme .mat-raised-button.mat-primary.mat-button-disabled, .everyday-theme .mat-raised-button.mat-accent.mat-button-disabled, .everyday-theme .mat-raised-button.mat-warn.mat-button-disabled, .everyday-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .everyday-theme .mat-fab.mat-primary.mat-button-disabled, .everyday-theme .mat-fab.mat-accent.mat-button-disabled, .everyday-theme .mat-fab.mat-warn.mat-button-disabled, .everyday-theme .mat-fab.mat-button-disabled.mat-button-disabled, .everyday-theme .mat-mini-fab.mat-primary.mat-button-disabled, .everyday-theme .mat-mini-fab.mat-accent.mat-button-disabled, .everyday-theme .mat-mini-fab.mat-warn.mat-button-disabled, .everyday-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: white;
}
.everyday-theme .mat-flat-button.mat-primary .mat-ripple-element, .everyday-theme .mat-raised-button.mat-primary .mat-ripple-element, .everyday-theme .mat-fab.mat-primary .mat-ripple-element, .everyday-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.everyday-theme .mat-flat-button.mat-accent .mat-ripple-element, .everyday-theme .mat-raised-button.mat-accent .mat-ripple-element, .everyday-theme .mat-fab.mat-accent .mat-ripple-element, .everyday-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(14, 13, 38, 0.1);
}
.everyday-theme .mat-flat-button.mat-warn .mat-ripple-element, .everyday-theme .mat-raised-button.mat-warn .mat-ripple-element, .everyday-theme .mat-fab.mat-warn .mat-ripple-element, .everyday-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.everyday-theme .mat-stroked-button:not([class*=mat-elevation-z]), .everyday-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(14, 13, 38, 0.2), 0px 2px 2px 0px rgba(14, 13, 38, 0.14), 0px 1px 5px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(14, 13, 38, 0.2), 0px 8px 10px 1px rgba(14, 13, 38, 0.14), 0px 3px 14px 2px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-fab:not([class*=mat-elevation-z]), .everyday-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(14, 13, 38, 0.2), 0px 6px 10px 0px rgba(14, 13, 38, 0.14), 0px 1px 18px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .everyday-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(14, 13, 38, 0.2), 0px 12px 17px 2px rgba(14, 13, 38, 0.14), 0px 5px 22px 4px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .everyday-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.everyday-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(14, 13, 38, 0.2), 0px 2px 2px 0px rgba(14, 13, 38, 0.14), 0px 1px 5px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.everyday-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.everyday-theme .mat-button-toggle {
  color: #dbdbde;
}
.everyday-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: white;
}
.everyday-theme .mat-button-toggle-appearance-standard {
  color: #0e0d26;
  background: white;
}
.everyday-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.everyday-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #dbdbde;
}
.everyday-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #dbdbde;
}
.everyday-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #dbdbde;
}
.everyday-theme .mat-button-toggle-checked {
  background-color: white;
  color: #0e0d26;
}
.everyday-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #0e0d26;
}
.everyday-theme .mat-button-toggle-disabled {
  color: #9f9ea8;
  background-color: white;
}
.everyday-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.everyday-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: white;
}
.everyday-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.everyday-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #dbdbde;
}
.everyday-theme .mat-card {
  background: white;
  color: #0e0d26;
}
.everyday-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(14, 13, 38, 0.2), 0px 1px 1px 0px rgba(14, 13, 38, 0.14), 0px 1px 3px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-card-subtitle {
  color: #0e0d26;
}
.everyday-theme .mat-checkbox-frame {
  border-color: #0e0d26;
}
.everyday-theme .mat-checkbox-checkmark {
  fill: white;
}
.everyday-theme .mat-checkbox-checkmark-path {
  stroke: white !important;
}
.everyday-theme .mat-checkbox-mixedmark {
  background-color: white;
}
.everyday-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .everyday-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #d44500;
}
.everyday-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .everyday-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0e0d26;
}
.everyday-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .everyday-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #0e0d26;
}
.everyday-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .everyday-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.everyday-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.everyday-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: #dbdbde;
}
.everyday-theme .mat-checkbox .mat-ripple-element {
  background-color: #0E0D26;
}
.everyday-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.everyday-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #d44500;
}
.everyday-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.everyday-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #0e0d26;
}
.everyday-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.everyday-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #0e0d26;
}
.everyday-theme .mat-chip.mat-standard-chip {
  background-color: white;
  color: #0e0d26;
}
.everyday-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #0e0d26;
  opacity: 0.4;
}
.everyday-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(14, 13, 38, 0.2), 0px 3px 4px 0px rgba(14, 13, 38, 0.14), 0px 1px 8px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.everyday-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.everyday-theme .mat-chip.mat-standard-chip::after {
  background: #0E0D26;
}
.everyday-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #d44500;
  color: white;
}
.everyday-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.everyday-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.everyday-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #0e0d26;
  color: white;
}
.everyday-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.everyday-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.everyday-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #0e0d26;
  color: #0e0d26;
}
.everyday-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: #0e0d26;
  opacity: 0.4;
}
.everyday-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(14, 13, 38, 0.1);
}
.everyday-theme .mat-table {
  background: white;
}
.everyday-theme .mat-table thead, .everyday-theme .mat-table tbody, .everyday-theme .mat-table tfoot,
.everyday-theme mat-header-row, .everyday-theme mat-row, .everyday-theme mat-footer-row,
.everyday-theme [mat-header-row], .everyday-theme [mat-row], .everyday-theme [mat-footer-row],
.everyday-theme .mat-table-sticky {
  background: inherit;
}
.everyday-theme mat-row, .everyday-theme mat-header-row, .everyday-theme mat-footer-row,
.everyday-theme th.mat-header-cell, .everyday-theme td.mat-cell, .everyday-theme td.mat-footer-cell {
  border-bottom-color: #dbdbde;
}
.everyday-theme .mat-header-cell {
  color: #0e0d26;
}
.everyday-theme .mat-cell, .everyday-theme .mat-footer-cell {
  color: #0e0d26;
}
.everyday-theme .mat-calendar-arrow {
  fill: #0e0d26;
}
.everyday-theme .mat-datepicker-toggle,
.everyday-theme .mat-datepicker-content .mat-calendar-next-button,
.everyday-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: #0e0d26;
}
.everyday-theme .mat-calendar-table-header-divider::after {
  background: #dbdbde;
}
.everyday-theme .mat-calendar-table-header,
.everyday-theme .mat-calendar-body-label {
  color: #0e0d26;
}
.everyday-theme .mat-calendar-body-cell-content,
.everyday-theme .mat-date-range-input-separator {
  color: #0e0d26;
  border-color: transparent;
}
.everyday-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #dbdbde;
}
.everyday-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: #dbdbde;
}
.everyday-theme .mat-calendar-body-in-preview {
  color: #dbdbde;
}
.everyday-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #dbdbde;
}
.everyday-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(219, 219, 222, 0.8);
}
.everyday-theme .mat-calendar-body-in-range::before {
  background: rgba(212, 69, 0, 0.2);
}
.everyday-theme .mat-calendar-body-comparison-identical,
.everyday-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.everyday-theme .mat-calendar-body-comparison-bridge-start::before,
.everyday-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(212, 69, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.everyday-theme .mat-calendar-body-comparison-bridge-end::before,
.everyday-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(212, 69, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.everyday-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.everyday-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.everyday-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.everyday-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.everyday-theme .mat-calendar-body-selected {
  background-color: #d44500;
  color: white;
}
.everyday-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(212, 69, 0, 0.4);
}
.everyday-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.everyday-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.everyday-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(212, 69, 0, 0.3);
}
@media (hover: hover) {
  .everyday-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(212, 69, 0, 0.3);
  }
}
.everyday-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
  background-color: white;
  color: #0e0d26;
}
.everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(14, 13, 38, 0.2);
}
.everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.everyday-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(14, 13, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.everyday-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(14, 13, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #0e0d26;
  color: #0e0d26;
}
.everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(14, 13, 38, 0.4);
}
.everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #0e0d26;
}
.everyday-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.everyday-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(14, 13, 38, 0.3);
}
@media (hover: hover) {
  .everyday-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(14, 13, 38, 0.3);
  }
}
.everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(14, 13, 38, 0.2);
}
.everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.everyday-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(14, 13, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.everyday-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(14, 13, 38, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #0e0d26;
  color: white;
}
.everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(14, 13, 38, 0.4);
}
.everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.everyday-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.everyday-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(14, 13, 38, 0.3);
}
@media (hover: hover) {
  .everyday-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(14, 13, 38, 0.3);
  }
}
.everyday-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(14, 13, 38, 0.2), 0px 24px 38px 3px rgba(14, 13, 38, 0.14), 0px 9px 46px 8px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-datepicker-toggle-active {
  color: #d44500;
}
.everyday-theme .mat-datepicker-toggle-active.mat-accent {
  color: #0e0d26;
}
.everyday-theme .mat-datepicker-toggle-active.mat-warn {
  color: #0e0d26;
}
.everyday-theme .mat-date-range-input-inner[disabled] {
  color: #dbdbde;
}
.everyday-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(14, 13, 38, 0.2), 0px 24px 38px 3px rgba(14, 13, 38, 0.14), 0px 9px 46px 8px rgba(14, 13, 38, 0.12);
  background: white;
  color: #0e0d26;
}
.everyday-theme .mat-divider {
  border-top-color: #dbdbde;
}
.everyday-theme .mat-divider-vertical {
  border-right-color: #dbdbde;
}
.everyday-theme .mat-expansion-panel {
  background: white;
  color: #0e0d26;
}
.everyday-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(14, 13, 38, 0.2), 0px 2px 2px 0px rgba(14, 13, 38, 0.14), 0px 1px 5px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-action-row {
  border-top-color: #dbdbde;
}
.everyday-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .everyday-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .everyday-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: white;
}
@media (hover: none) {
  .everyday-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.everyday-theme .mat-expansion-panel-header-title {
  color: #0e0d26;
}
.everyday-theme .mat-expansion-panel-header-description,
.everyday-theme .mat-expansion-indicator::after {
  color: #0e0d26;
}
.everyday-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: #9f9ea8;
}
.everyday-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.everyday-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.everyday-theme .mat-form-field-label {
  color: rgba(14, 13, 38, 0.6);
}
.everyday-theme .mat-hint {
  color: rgba(14, 13, 38, 0.6);
}
.everyday-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #d44500;
}
.everyday-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #0e0d26;
}
.everyday-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #0e0d26;
}
.everyday-theme .mat-focused .mat-form-field-required-marker {
  color: #0e0d26;
}
.everyday-theme .mat-form-field-ripple {
  background-color: rgba(219, 219, 222, 0.87);
}
.everyday-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #d44500;
}
.everyday-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #0e0d26;
}
.everyday-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #0e0d26;
}
.everyday-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #d44500;
}
.everyday-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #0e0d26;
}
.everyday-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #0e0d26;
}
.everyday-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #0e0d26;
}
.everyday-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.everyday-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #0e0d26;
}
.everyday-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.everyday-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #0e0d26;
}
.everyday-theme .mat-error {
  color: #0e0d26;
}
.everyday-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #0e0d26;
}
.everyday-theme .mat-form-field-appearance-legacy .mat-hint {
  color: #0e0d26;
}
.everyday-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(219, 219, 222, 0.42);
}
.everyday-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(219, 219, 222, 0.42) 0%, rgba(219, 219, 222, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.everyday-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(219, 219, 222, 0.42);
}
.everyday-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(219, 219, 222, 0.42) 0%, rgba(219, 219, 222, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.everyday-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(14, 13, 38, 0.04);
}
.everyday-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(14, 13, 38, 0.02);
}
.everyday-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(219, 219, 222, 0.42);
}
.everyday-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: #dbdbde;
}
.everyday-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.everyday-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(219, 219, 222, 0.12);
}
.everyday-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(219, 219, 222, 0.87);
}
.everyday-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #d44500;
}
.everyday-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #0e0d26;
}
.everyday-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #0e0d26;
}
.everyday-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #0e0d26;
}
.everyday-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #dbdbde;
}
.everyday-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(219, 219, 222, 0.06);
}
.everyday-theme .mat-icon.mat-primary {
  color: #d44500;
}
.everyday-theme .mat-icon.mat-accent {
  color: #0e0d26;
}
.everyday-theme .mat-icon.mat-warn {
  color: #0e0d26;
}
.everyday-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #0e0d26;
}
.everyday-theme .mat-input-element:disabled,
.everyday-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: #dbdbde;
}
.everyday-theme .mat-input-element {
  caret-color: #d44500;
}
.everyday-theme .mat-input-element::placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.everyday-theme .mat-input-element::-moz-placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.everyday-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.everyday-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.everyday-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #0e0d26;
}
.everyday-theme .mat-form-field.mat-warn .mat-input-element,
.everyday-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #0e0d26;
}
.everyday-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #0e0d26;
}
.everyday-theme .mat-list-base .mat-list-item {
  color: #0e0d26;
}
.everyday-theme .mat-list-base .mat-list-option {
  color: #0e0d26;
}
.everyday-theme .mat-list-base .mat-subheader {
  color: #0e0d26;
}
.everyday-theme .mat-list-base .mat-list-item-disabled {
  background-color: white;
  color: #dbdbde;
}
.everyday-theme .mat-list-option:hover, .everyday-theme .mat-list-option:focus,
.everyday-theme .mat-nav-list .mat-list-item:hover,
.everyday-theme .mat-nav-list .mat-list-item:focus,
.everyday-theme .mat-action-list .mat-list-item:hover,
.everyday-theme .mat-action-list .mat-list-item:focus {
  background: white;
}
.everyday-theme .mat-list-single-selected-option, .everyday-theme .mat-list-single-selected-option:hover, .everyday-theme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.everyday-theme .mat-menu-panel {
  background: white;
}
.everyday-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-menu-item {
  background: transparent;
  color: #0e0d26;
}
.everyday-theme .mat-menu-item[disabled],
.everyday-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.everyday-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: #dbdbde;
}
.everyday-theme .mat-menu-item .mat-icon-no-color,
.everyday-theme .mat-menu-submenu-icon {
  color: #0e0d26;
}
.everyday-theme .mat-menu-item:hover:not([disabled]),
.everyday-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.everyday-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.everyday-theme .mat-menu-item-highlighted:not([disabled]) {
  background: white;
}
.everyday-theme .mat-paginator {
  background: white;
}
.everyday-theme .mat-paginator,
.everyday-theme .mat-paginator-page-size .mat-select-trigger {
  color: #0e0d26;
}
.everyday-theme .mat-paginator-decrement,
.everyday-theme .mat-paginator-increment {
  border-top: 2px solid #0e0d26;
  border-right: 2px solid #0e0d26;
}
.everyday-theme .mat-paginator-first,
.everyday-theme .mat-paginator-last {
  border-top: 2px solid #0e0d26;
}
.everyday-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.everyday-theme .mat-icon-button[disabled] .mat-paginator-increment,
.everyday-theme .mat-icon-button[disabled] .mat-paginator-first,
.everyday-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: #dbdbde;
}
.everyday-theme .mat-progress-bar-background {
  fill: #f4d1bf;
}
.everyday-theme .mat-progress-bar-buffer {
  background-color: #f4d1bf;
}
.everyday-theme .mat-progress-bar-fill::after {
  background-color: #d44500;
}
.everyday-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #c3c3c9;
}
.everyday-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #c3c3c9;
}
.everyday-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #0e0d26;
}
.everyday-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #c3c3c9;
}
.everyday-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #c3c3c9;
}
.everyday-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #0e0d26;
}
.everyday-theme .mat-progress-spinner circle, .everyday-theme .mat-spinner circle {
  stroke: #d44500;
}
.everyday-theme .mat-progress-spinner.mat-accent circle, .everyday-theme .mat-spinner.mat-accent circle {
  stroke: #0e0d26;
}
.everyday-theme .mat-progress-spinner.mat-warn circle, .everyday-theme .mat-spinner.mat-warn circle {
  stroke: #0e0d26;
}
.everyday-theme .mat-radio-outer-circle {
  border-color: #0e0d26;
}
.everyday-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #d44500;
}
.everyday-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.everyday-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .everyday-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .everyday-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #d44500;
}
.everyday-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0e0d26;
}
.everyday-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.everyday-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .everyday-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .everyday-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #0e0d26;
}
.everyday-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0e0d26;
}
.everyday-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.everyday-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .everyday-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .everyday-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #0e0d26;
}
.everyday-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.everyday-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: #dbdbde;
}
.everyday-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.everyday-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #dbdbde;
}
.everyday-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: #dbdbde;
}
.everyday-theme .mat-radio-button .mat-ripple-element {
  background-color: #0E0D26;
}
.everyday-theme .mat-select-value {
  color: #0e0d26;
}
.everyday-theme .mat-select-placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.everyday-theme .mat-select-disabled .mat-select-value {
  color: #dbdbde;
}
.everyday-theme .mat-select-arrow {
  color: #0e0d26;
}
.everyday-theme .mat-select-panel {
  background: white;
}
.everyday-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.everyday-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #d44500;
}
.everyday-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #0e0d26;
}
.everyday-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #0e0d26;
}
.everyday-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #0e0d26;
}
.everyday-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: #dbdbde;
}
.everyday-theme .mat-drawer-container {
  background-color: white;
  color: #0e0d26;
}
.everyday-theme .mat-drawer {
  background-color: white;
  color: #0e0d26;
}
.everyday-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.everyday-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(14, 13, 38, 0.2), 0px 16px 24px 2px rgba(14, 13, 38, 0.14), 0px 6px 30px 5px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-drawer-side {
  border-right: solid 1px #dbdbde;
}
.everyday-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px #dbdbde;
  border-right: none;
}
.everyday-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px #dbdbde;
  border-right: none;
}
.everyday-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px #dbdbde;
}
.everyday-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.everyday-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0e0d26;
}
.everyday-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(14, 13, 38, 0.54);
}
.everyday-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #0e0d26;
}
.everyday-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #d44500;
}
.everyday-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(212, 69, 0, 0.54);
}
.everyday-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #d44500;
}
.everyday-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #0e0d26;
}
.everyday-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(14, 13, 38, 0.54);
}
.everyday-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #0e0d26;
}
.everyday-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #0e0d26;
}
.everyday-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(14, 13, 38, 0.2), 0px 1px 1px 0px rgba(14, 13, 38, 0.14), 0px 1px 3px 0px rgba(14, 13, 38, 0.12);
  background-color: #fafafa;
}
.everyday-theme .mat-slide-toggle-bar {
  background-color: #dbdbde;
}
.everyday-theme .mat-slider-track-background {
  background-color: #0e0d26;
}
.everyday-theme .mat-slider.mat-primary .mat-slider-track-fill,
.everyday-theme .mat-slider.mat-primary .mat-slider-thumb,
.everyday-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #d44500;
}
.everyday-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.everyday-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(212, 69, 0, 0.2);
}
.everyday-theme .mat-slider.mat-accent .mat-slider-track-fill,
.everyday-theme .mat-slider.mat-accent .mat-slider-thumb,
.everyday-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #0e0d26;
}
.everyday-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: #0e0d26;
}
.everyday-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(14, 13, 38, 0.2);
}
.everyday-theme .mat-slider.mat-warn .mat-slider-track-fill,
.everyday-theme .mat-slider.mat-warn .mat-slider-thumb,
.everyday-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #0e0d26;
}
.everyday-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.everyday-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(14, 13, 38, 0.2);
}
.everyday-theme .mat-slider:hover .mat-slider-track-background,
.everyday-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: #0e0d26;
}
.everyday-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.everyday-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.everyday-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: #0e0d26;
}
.everyday-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: #0e0d26;
}
.everyday-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.everyday-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: #0e0d26;
}
.everyday-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.everyday-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: #0e0d26;
}
.everyday-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: #0e0d26;
  background-color: transparent;
}
.everyday-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .everyday-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: #0e0d26;
}
.everyday-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .everyday-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: #0e0d26;
}
.everyday-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(14, 13, 38, 0.7);
}
.everyday-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(14, 13, 38, 0.7), rgba(14, 13, 38, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(14, 13, 38, 0.7), rgba(14, 13, 38, 0.7) 2px, transparent 0, transparent);
}
.everyday-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(14, 13, 38, 0.7), rgba(14, 13, 38, 0.7) 2px, transparent 0, transparent);
}
.everyday-theme .mat-step-header.cdk-keyboard-focused, .everyday-theme .mat-step-header.cdk-program-focused, .everyday-theme .mat-step-header:hover:not([aria-disabled]), .everyday-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: white;
}
.everyday-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .everyday-theme .mat-step-header:hover {
    background: none;
  }
}
.everyday-theme .mat-step-header .mat-step-label,
.everyday-theme .mat-step-header .mat-step-optional {
  color: #0e0d26;
}
.everyday-theme .mat-step-header .mat-step-icon {
  background-color: #0e0d26;
  color: white;
}
.everyday-theme .mat-step-header .mat-step-icon-selected,
.everyday-theme .mat-step-header .mat-step-icon-state-done,
.everyday-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #d44500;
  color: white;
}
.everyday-theme .mat-step-header.mat-accent .mat-step-icon {
  color: #0e0d26;
}
.everyday-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.everyday-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.everyday-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #0e0d26;
  color: #0e0d26;
}
.everyday-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.everyday-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.everyday-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.everyday-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #0e0d26;
  color: white;
}
.everyday-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #0e0d26;
}
.everyday-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: #0e0d26;
}
.everyday-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #0e0d26;
}
.everyday-theme .mat-stepper-horizontal, .everyday-theme .mat-stepper-vertical {
  background-color: white;
}
.everyday-theme .mat-stepper-vertical-line::before {
  border-left-color: #dbdbde;
}
.everyday-theme .mat-horizontal-stepper-header::before,
.everyday-theme .mat-horizontal-stepper-header::after,
.everyday-theme .mat-stepper-horizontal-line {
  border-top-color: #dbdbde;
}
.everyday-theme .mat-sort-header-arrow {
  color: #0e0d26;
}
.everyday-theme .mat-tab-nav-bar,
.everyday-theme .mat-tab-header {
  border-bottom: 1px solid #dbdbde;
}
.everyday-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.everyday-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid #dbdbde;
  border-bottom: none;
}
.everyday-theme .mat-tab-label, .everyday-theme .mat-tab-link {
  color: #0e0d26;
}
.everyday-theme .mat-tab-label.mat-tab-disabled, .everyday-theme .mat-tab-link.mat-tab-disabled {
  color: #dbdbde;
}
.everyday-theme .mat-tab-header-pagination-chevron {
  border-color: #0e0d26;
}
.everyday-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #dbdbde;
}
.everyday-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.everyday-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.everyday-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(212, 69, 0, 0.3);
}
.everyday-theme .mat-tab-group.mat-primary .mat-ink-bar, .everyday-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #d44500;
}
.everyday-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .everyday-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .everyday-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .everyday-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.everyday-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.everyday-theme .mat-tab-group.mat-accent .mat-ink-bar, .everyday-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #0e0d26;
}
.everyday-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .everyday-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .everyday-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .everyday-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: #0e0d26;
}
.everyday-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.everyday-theme .mat-tab-group.mat-warn .mat-ink-bar, .everyday-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #0e0d26;
}
.everyday-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .everyday-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .everyday-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .everyday-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.everyday-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(212, 69, 0, 0.3);
}
.everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #d44500;
}
.everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.everyday-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.everyday-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.everyday-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #0e0d26;
}
.everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: #0e0d26;
}
.everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(14, 13, 38, 0.4);
}
.everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: #0e0d26;
}
.everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #0e0d26;
  opacity: 0.4;
}
.everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.everyday-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.everyday-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: #0e0d26;
  opacity: 0.12;
}
.everyday-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .everyday-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.everyday-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(14, 13, 38, 0.3);
}
.everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #0e0d26;
}
.everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.everyday-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.everyday-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.everyday-theme .mat-toolbar {
  background: white;
  color: #0e0d26;
}
.everyday-theme .mat-toolbar.mat-primary {
  background: #d44500;
  color: white;
}
.everyday-theme .mat-toolbar.mat-accent {
  background: #0e0d26;
  color: #0e0d26;
}
.everyday-theme .mat-toolbar.mat-warn {
  background: #0e0d26;
  color: white;
}
.everyday-theme .mat-toolbar .mat-form-field-underline,
.everyday-theme .mat-toolbar .mat-form-field-ripple,
.everyday-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.everyday-theme .mat-toolbar .mat-form-field-label,
.everyday-theme .mat-toolbar .mat-focused .mat-form-field-label,
.everyday-theme .mat-toolbar .mat-select-value,
.everyday-theme .mat-toolbar .mat-select-arrow,
.everyday-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.everyday-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.everyday-theme .mat-tree {
  background: white;
}
.everyday-theme .mat-tree-node,
.everyday-theme .mat-nested-tree-node {
  color: #0e0d26;
}
.everyday-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(14, 13, 38, 0.2), 0px 6px 10px 0px rgba(14, 13, 38, 0.14), 0px 1px 18px 0px rgba(14, 13, 38, 0.12);
}
.everyday-theme .mat-simple-snackbar-action {
  color: #0e0d26;
}

.bigw-theme .mat-ripple-element {
  background-color: rgba(14, 13, 38, 0.1);
}
.bigw-theme .mat-option {
  color: #0e0d26;
}
.bigw-theme .mat-option:hover:not(.mat-option-disabled), .bigw-theme .mat-option:focus:not(.mat-option-disabled) {
  background: white;
}
.bigw-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: white;
}
.bigw-theme .mat-option.mat-active {
  background: white;
  color: #0e0d26;
}
.bigw-theme .mat-option.mat-option-disabled {
  color: #dbdbde;
}
.bigw-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #002a78;
}
.bigw-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0046c8;
}
.bigw-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0046c8;
}
.bigw-theme .mat-optgroup-label {
  color: #0e0d26;
}
.bigw-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: #dbdbde;
}
.bigw-theme .mat-pseudo-checkbox {
  color: #0e0d26;
}
.bigw-theme .mat-pseudo-checkbox::after {
  color: white;
}
.bigw-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.bigw-theme .mat-primary .mat-pseudo-checkbox-checked,
.bigw-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #002a78;
}
.bigw-theme .mat-pseudo-checkbox-checked,
.bigw-theme .mat-pseudo-checkbox-indeterminate,
.bigw-theme .mat-accent .mat-pseudo-checkbox-checked,
.bigw-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #0046c8;
}
.bigw-theme .mat-warn .mat-pseudo-checkbox-checked,
.bigw-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #0046c8;
}
.bigw-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.bigw-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.bigw-theme .mat-app-background, .bigw-theme.mat-app-background {
  background-color: white;
  color: #0e0d26;
}
.bigw-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(14, 13, 38, 0.2), 0px 1px 1px 0px rgba(14, 13, 38, 0.14), 0px 1px 3px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(14, 13, 38, 0.2), 0px 2px 2px 0px rgba(14, 13, 38, 0.14), 0px 1px 5px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(14, 13, 38, 0.2), 0px 3px 4px 0px rgba(14, 13, 38, 0.14), 0px 1px 8px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(14, 13, 38, 0.2), 0px 5px 8px 0px rgba(14, 13, 38, 0.14), 0px 1px 14px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(14, 13, 38, 0.2), 0px 6px 10px 0px rgba(14, 13, 38, 0.14), 0px 1px 18px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(14, 13, 38, 0.2), 0px 7px 10px 1px rgba(14, 13, 38, 0.14), 0px 2px 16px 1px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(14, 13, 38, 0.2), 0px 8px 10px 1px rgba(14, 13, 38, 0.14), 0px 3px 14px 2px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(14, 13, 38, 0.2), 0px 9px 12px 1px rgba(14, 13, 38, 0.14), 0px 3px 16px 2px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(14, 13, 38, 0.2), 0px 10px 14px 1px rgba(14, 13, 38, 0.14), 0px 4px 18px 3px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(14, 13, 38, 0.2), 0px 11px 15px 1px rgba(14, 13, 38, 0.14), 0px 4px 20px 3px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(14, 13, 38, 0.2), 0px 12px 17px 2px rgba(14, 13, 38, 0.14), 0px 5px 22px 4px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(14, 13, 38, 0.2), 0px 13px 19px 2px rgba(14, 13, 38, 0.14), 0px 5px 24px 4px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(14, 13, 38, 0.2), 0px 14px 21px 2px rgba(14, 13, 38, 0.14), 0px 5px 26px 4px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(14, 13, 38, 0.2), 0px 15px 22px 2px rgba(14, 13, 38, 0.14), 0px 6px 28px 5px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(14, 13, 38, 0.2), 0px 16px 24px 2px rgba(14, 13, 38, 0.14), 0px 6px 30px 5px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(14, 13, 38, 0.2), 0px 17px 26px 2px rgba(14, 13, 38, 0.14), 0px 6px 32px 5px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(14, 13, 38, 0.2), 0px 18px 28px 2px rgba(14, 13, 38, 0.14), 0px 7px 34px 6px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(14, 13, 38, 0.2), 0px 19px 29px 2px rgba(14, 13, 38, 0.14), 0px 7px 36px 6px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(14, 13, 38, 0.2), 0px 20px 31px 3px rgba(14, 13, 38, 0.14), 0px 8px 38px 7px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(14, 13, 38, 0.2), 0px 21px 33px 3px rgba(14, 13, 38, 0.14), 0px 8px 40px 7px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(14, 13, 38, 0.2), 0px 22px 35px 3px rgba(14, 13, 38, 0.14), 0px 8px 42px 7px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(14, 13, 38, 0.2), 0px 23px 36px 3px rgba(14, 13, 38, 0.14), 0px 9px 44px 8px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(14, 13, 38, 0.2), 0px 24px 38px 3px rgba(14, 13, 38, 0.14), 0px 9px 46px 8px rgba(14, 13, 38, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.bigw-theme .mat-autocomplete-panel {
  background: white;
  color: #0e0d26;
}
.bigw-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.bigw-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: #0e0d26;
}
.bigw-theme .mat-badge {
  position: relative;
}
.bigw-theme .mat-badge.mat-badge {
  overflow: visible;
}
.bigw-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.bigw-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.bigw-theme .ng-animate-disabled .mat-badge-content,
.bigw-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.bigw-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.bigw-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.bigw-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.bigw-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.bigw-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .bigw-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.bigw-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .bigw-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.bigw-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .bigw-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.bigw-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .bigw-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.bigw-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.bigw-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.bigw-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.bigw-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .bigw-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.bigw-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .bigw-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.bigw-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .bigw-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.bigw-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .bigw-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.bigw-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.bigw-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.bigw-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.bigw-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .bigw-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.bigw-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .bigw-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.bigw-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .bigw-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.bigw-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .bigw-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.bigw-theme .mat-badge-content {
  color: white;
  background: #002a78;
}
.cdk-high-contrast-active .bigw-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.bigw-theme .mat-badge-accent .mat-badge-content {
  background: #0046c8;
  color: #0046c8;
}
.bigw-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #0046c8;
}
.bigw-theme .mat-badge-disabled .mat-badge-content {
  background: #9f9ea8;
  color: #dbdbde;
}
.bigw-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(14, 13, 38, 0.2), 0px 16px 24px 2px rgba(14, 13, 38, 0.14), 0px 6px 30px 5px rgba(14, 13, 38, 0.12);
  background: white;
  color: #0e0d26;
}
.bigw-theme .mat-button, .bigw-theme .mat-icon-button, .bigw-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.bigw-theme .mat-button.mat-primary, .bigw-theme .mat-icon-button.mat-primary, .bigw-theme .mat-stroked-button.mat-primary {
  color: #002a78;
}
.bigw-theme .mat-button.mat-accent, .bigw-theme .mat-icon-button.mat-accent, .bigw-theme .mat-stroked-button.mat-accent {
  color: #0046c8;
}
.bigw-theme .mat-button.mat-warn, .bigw-theme .mat-icon-button.mat-warn, .bigw-theme .mat-stroked-button.mat-warn {
  color: #0046c8;
}
.bigw-theme .mat-button.mat-primary.mat-button-disabled, .bigw-theme .mat-button.mat-accent.mat-button-disabled, .bigw-theme .mat-button.mat-warn.mat-button-disabled, .bigw-theme .mat-button.mat-button-disabled.mat-button-disabled, .bigw-theme .mat-icon-button.mat-primary.mat-button-disabled, .bigw-theme .mat-icon-button.mat-accent.mat-button-disabled, .bigw-theme .mat-icon-button.mat-warn.mat-button-disabled, .bigw-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .bigw-theme .mat-stroked-button.mat-primary.mat-button-disabled, .bigw-theme .mat-stroked-button.mat-accent.mat-button-disabled, .bigw-theme .mat-stroked-button.mat-warn.mat-button-disabled, .bigw-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: #9f9ea8;
}
.bigw-theme .mat-button.mat-primary .mat-button-focus-overlay, .bigw-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .bigw-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #002a78;
}
.bigw-theme .mat-button.mat-accent .mat-button-focus-overlay, .bigw-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .bigw-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #0046c8;
}
.bigw-theme .mat-button.mat-warn .mat-button-focus-overlay, .bigw-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .bigw-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #0046c8;
}
.bigw-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .bigw-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .bigw-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.bigw-theme .mat-button .mat-ripple-element, .bigw-theme .mat-icon-button .mat-ripple-element, .bigw-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.bigw-theme .mat-button-focus-overlay {
  background: #0E0D26;
}
.bigw-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: #dbdbde;
}
.bigw-theme .mat-flat-button, .bigw-theme .mat-raised-button, .bigw-theme .mat-fab, .bigw-theme .mat-mini-fab {
  color: #0e0d26;
  background-color: white;
}
.bigw-theme .mat-flat-button.mat-primary, .bigw-theme .mat-raised-button.mat-primary, .bigw-theme .mat-fab.mat-primary, .bigw-theme .mat-mini-fab.mat-primary {
  color: white;
}
.bigw-theme .mat-flat-button.mat-accent, .bigw-theme .mat-raised-button.mat-accent, .bigw-theme .mat-fab.mat-accent, .bigw-theme .mat-mini-fab.mat-accent {
  color: #0046c8;
}
.bigw-theme .mat-flat-button.mat-warn, .bigw-theme .mat-raised-button.mat-warn, .bigw-theme .mat-fab.mat-warn, .bigw-theme .mat-mini-fab.mat-warn {
  color: white;
}
.bigw-theme .mat-flat-button.mat-primary.mat-button-disabled, .bigw-theme .mat-flat-button.mat-accent.mat-button-disabled, .bigw-theme .mat-flat-button.mat-warn.mat-button-disabled, .bigw-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bigw-theme .mat-raised-button.mat-primary.mat-button-disabled, .bigw-theme .mat-raised-button.mat-accent.mat-button-disabled, .bigw-theme .mat-raised-button.mat-warn.mat-button-disabled, .bigw-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bigw-theme .mat-fab.mat-primary.mat-button-disabled, .bigw-theme .mat-fab.mat-accent.mat-button-disabled, .bigw-theme .mat-fab.mat-warn.mat-button-disabled, .bigw-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bigw-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bigw-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bigw-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bigw-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: #9f9ea8;
}
.bigw-theme .mat-flat-button.mat-primary, .bigw-theme .mat-raised-button.mat-primary, .bigw-theme .mat-fab.mat-primary, .bigw-theme .mat-mini-fab.mat-primary {
  background-color: #002a78;
}
.bigw-theme .mat-flat-button.mat-accent, .bigw-theme .mat-raised-button.mat-accent, .bigw-theme .mat-fab.mat-accent, .bigw-theme .mat-mini-fab.mat-accent {
  background-color: #0046c8;
}
.bigw-theme .mat-flat-button.mat-warn, .bigw-theme .mat-raised-button.mat-warn, .bigw-theme .mat-fab.mat-warn, .bigw-theme .mat-mini-fab.mat-warn {
  background-color: #0046c8;
}
.bigw-theme .mat-flat-button.mat-primary.mat-button-disabled, .bigw-theme .mat-flat-button.mat-accent.mat-button-disabled, .bigw-theme .mat-flat-button.mat-warn.mat-button-disabled, .bigw-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .bigw-theme .mat-raised-button.mat-primary.mat-button-disabled, .bigw-theme .mat-raised-button.mat-accent.mat-button-disabled, .bigw-theme .mat-raised-button.mat-warn.mat-button-disabled, .bigw-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .bigw-theme .mat-fab.mat-primary.mat-button-disabled, .bigw-theme .mat-fab.mat-accent.mat-button-disabled, .bigw-theme .mat-fab.mat-warn.mat-button-disabled, .bigw-theme .mat-fab.mat-button-disabled.mat-button-disabled, .bigw-theme .mat-mini-fab.mat-primary.mat-button-disabled, .bigw-theme .mat-mini-fab.mat-accent.mat-button-disabled, .bigw-theme .mat-mini-fab.mat-warn.mat-button-disabled, .bigw-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: white;
}
.bigw-theme .mat-flat-button.mat-primary .mat-ripple-element, .bigw-theme .mat-raised-button.mat-primary .mat-ripple-element, .bigw-theme .mat-fab.mat-primary .mat-ripple-element, .bigw-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bigw-theme .mat-flat-button.mat-accent .mat-ripple-element, .bigw-theme .mat-raised-button.mat-accent .mat-ripple-element, .bigw-theme .mat-fab.mat-accent .mat-ripple-element, .bigw-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 70, 200, 0.1);
}
.bigw-theme .mat-flat-button.mat-warn .mat-ripple-element, .bigw-theme .mat-raised-button.mat-warn .mat-ripple-element, .bigw-theme .mat-fab.mat-warn .mat-ripple-element, .bigw-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bigw-theme .mat-stroked-button:not([class*=mat-elevation-z]), .bigw-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(14, 13, 38, 0.2), 0px 2px 2px 0px rgba(14, 13, 38, 0.14), 0px 1px 5px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(14, 13, 38, 0.2), 0px 8px 10px 1px rgba(14, 13, 38, 0.14), 0px 3px 14px 2px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-fab:not([class*=mat-elevation-z]), .bigw-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(14, 13, 38, 0.2), 0px 6px 10px 0px rgba(14, 13, 38, 0.14), 0px 1px 18px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .bigw-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(14, 13, 38, 0.2), 0px 12px 17px 2px rgba(14, 13, 38, 0.14), 0px 5px 22px 4px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .bigw-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.bigw-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(14, 13, 38, 0.2), 0px 2px 2px 0px rgba(14, 13, 38, 0.14), 0px 1px 5px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.bigw-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.bigw-theme .mat-button-toggle {
  color: #dbdbde;
}
.bigw-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: white;
}
.bigw-theme .mat-button-toggle-appearance-standard {
  color: #0e0d26;
  background: white;
}
.bigw-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.bigw-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #dbdbde;
}
.bigw-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #dbdbde;
}
.bigw-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #dbdbde;
}
.bigw-theme .mat-button-toggle-checked {
  background-color: white;
  color: #0e0d26;
}
.bigw-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: #0e0d26;
}
.bigw-theme .mat-button-toggle-disabled {
  color: #9f9ea8;
  background-color: white;
}
.bigw-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.bigw-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: white;
}
.bigw-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.bigw-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #dbdbde;
}
.bigw-theme .mat-card {
  background: white;
  color: #0e0d26;
}
.bigw-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(14, 13, 38, 0.2), 0px 1px 1px 0px rgba(14, 13, 38, 0.14), 0px 1px 3px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(14, 13, 38, 0.2), 0px 0px 0px 0px rgba(14, 13, 38, 0.14), 0px 0px 0px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-card-subtitle {
  color: #0e0d26;
}
.bigw-theme .mat-checkbox-frame {
  border-color: #0e0d26;
}
.bigw-theme .mat-checkbox-checkmark {
  fill: white;
}
.bigw-theme .mat-checkbox-checkmark-path {
  stroke: white !important;
}
.bigw-theme .mat-checkbox-mixedmark {
  background-color: white;
}
.bigw-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .bigw-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #002a78;
}
.bigw-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .bigw-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0046c8;
}
.bigw-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .bigw-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #0046c8;
}
.bigw-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .bigw-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.bigw-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.bigw-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: #dbdbde;
}
.bigw-theme .mat-checkbox .mat-ripple-element {
  background-color: #0E0D26;
}
.bigw-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.bigw-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #002a78;
}
.bigw-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.bigw-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #0046c8;
}
.bigw-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.bigw-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #0046c8;
}
.bigw-theme .mat-chip.mat-standard-chip {
  background-color: white;
  color: #0e0d26;
}
.bigw-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: #0e0d26;
  opacity: 0.4;
}
.bigw-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(14, 13, 38, 0.2), 0px 3px 4px 0px rgba(14, 13, 38, 0.14), 0px 1px 8px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.bigw-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.bigw-theme .mat-chip.mat-standard-chip::after {
  background: #0E0D26;
}
.bigw-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #002a78;
  color: white;
}
.bigw-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bigw-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bigw-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #0046c8;
  color: white;
}
.bigw-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.bigw-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.bigw-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #0046c8;
  color: #0046c8;
}
.bigw-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: #0046c8;
  opacity: 0.4;
}
.bigw-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 70, 200, 0.1);
}
.bigw-theme .mat-table {
  background: white;
}
.bigw-theme .mat-table thead, .bigw-theme .mat-table tbody, .bigw-theme .mat-table tfoot,
.bigw-theme mat-header-row, .bigw-theme mat-row, .bigw-theme mat-footer-row,
.bigw-theme [mat-header-row], .bigw-theme [mat-row], .bigw-theme [mat-footer-row],
.bigw-theme .mat-table-sticky {
  background: inherit;
}
.bigw-theme mat-row, .bigw-theme mat-header-row, .bigw-theme mat-footer-row,
.bigw-theme th.mat-header-cell, .bigw-theme td.mat-cell, .bigw-theme td.mat-footer-cell {
  border-bottom-color: #dbdbde;
}
.bigw-theme .mat-header-cell {
  color: #0e0d26;
}
.bigw-theme .mat-cell, .bigw-theme .mat-footer-cell {
  color: #0e0d26;
}
.bigw-theme .mat-calendar-arrow {
  fill: #0e0d26;
}
.bigw-theme .mat-datepicker-toggle,
.bigw-theme .mat-datepicker-content .mat-calendar-next-button,
.bigw-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: #0e0d26;
}
.bigw-theme .mat-calendar-table-header-divider::after {
  background: #dbdbde;
}
.bigw-theme .mat-calendar-table-header,
.bigw-theme .mat-calendar-body-label {
  color: #0e0d26;
}
.bigw-theme .mat-calendar-body-cell-content,
.bigw-theme .mat-date-range-input-separator {
  color: #0e0d26;
  border-color: transparent;
}
.bigw-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: #dbdbde;
}
.bigw-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: #dbdbde;
}
.bigw-theme .mat-calendar-body-in-preview {
  color: #dbdbde;
}
.bigw-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: #dbdbde;
}
.bigw-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(219, 219, 222, 0.8);
}
.bigw-theme .mat-calendar-body-in-range::before {
  background: rgba(0, 42, 120, 0.2);
}
.bigw-theme .mat-calendar-body-comparison-identical,
.bigw-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bigw-theme .mat-calendar-body-comparison-bridge-start::before,
.bigw-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 42, 120, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bigw-theme .mat-calendar-body-comparison-bridge-end::before,
.bigw-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 42, 120, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bigw-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bigw-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bigw-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bigw-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bigw-theme .mat-calendar-body-selected {
  background-color: #002a78;
  color: white;
}
.bigw-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 42, 120, 0.4);
}
.bigw-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bigw-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bigw-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 42, 120, 0.3);
}
@media (hover: hover) {
  .bigw-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 42, 120, 0.3);
  }
}
.bigw-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
  background-color: white;
  color: #0e0d26;
}
.bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 70, 200, 0.2);
}
.bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.bigw-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 70, 200, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.bigw-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 70, 200, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #0046c8;
  color: #0046c8;
}
.bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 70, 200, 0.4);
}
.bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #0046c8;
}
.bigw-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bigw-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 70, 200, 0.3);
}
@media (hover: hover) {
  .bigw-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 70, 200, 0.3);
  }
}
.bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(0, 70, 200, 0.2);
}
.bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.bigw-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 70, 200, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.bigw-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 70, 200, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #0046c8;
  color: white;
}
.bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 70, 200, 0.4);
}
.bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.bigw-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.bigw-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 70, 200, 0.3);
}
@media (hover: hover) {
  .bigw-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 70, 200, 0.3);
  }
}
.bigw-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(14, 13, 38, 0.2), 0px 24px 38px 3px rgba(14, 13, 38, 0.14), 0px 9px 46px 8px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-datepicker-toggle-active {
  color: #002a78;
}
.bigw-theme .mat-datepicker-toggle-active.mat-accent {
  color: #0046c8;
}
.bigw-theme .mat-datepicker-toggle-active.mat-warn {
  color: #0046c8;
}
.bigw-theme .mat-date-range-input-inner[disabled] {
  color: #dbdbde;
}
.bigw-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(14, 13, 38, 0.2), 0px 24px 38px 3px rgba(14, 13, 38, 0.14), 0px 9px 46px 8px rgba(14, 13, 38, 0.12);
  background: white;
  color: #0e0d26;
}
.bigw-theme .mat-divider {
  border-top-color: #dbdbde;
}
.bigw-theme .mat-divider-vertical {
  border-right-color: #dbdbde;
}
.bigw-theme .mat-expansion-panel {
  background: white;
  color: #0e0d26;
}
.bigw-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(14, 13, 38, 0.2), 0px 2px 2px 0px rgba(14, 13, 38, 0.14), 0px 1px 5px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-action-row {
  border-top-color: #dbdbde;
}
.bigw-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .bigw-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .bigw-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: white;
}
@media (hover: none) {
  .bigw-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.bigw-theme .mat-expansion-panel-header-title {
  color: #0e0d26;
}
.bigw-theme .mat-expansion-panel-header-description,
.bigw-theme .mat-expansion-indicator::after {
  color: #0e0d26;
}
.bigw-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: #9f9ea8;
}
.bigw-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.bigw-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.bigw-theme .mat-form-field-label {
  color: rgba(14, 13, 38, 0.6);
}
.bigw-theme .mat-hint {
  color: rgba(14, 13, 38, 0.6);
}
.bigw-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #002a78;
}
.bigw-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #0046c8;
}
.bigw-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #0046c8;
}
.bigw-theme .mat-focused .mat-form-field-required-marker {
  color: #0046c8;
}
.bigw-theme .mat-form-field-ripple {
  background-color: rgba(219, 219, 222, 0.87);
}
.bigw-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #002a78;
}
.bigw-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #0046c8;
}
.bigw-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #0046c8;
}
.bigw-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #002a78;
}
.bigw-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #0046c8;
}
.bigw-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #0046c8;
}
.bigw-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #0046c8;
}
.bigw-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.bigw-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #0046c8;
}
.bigw-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.bigw-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #0046c8;
}
.bigw-theme .mat-error {
  color: #0046c8;
}
.bigw-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #0e0d26;
}
.bigw-theme .mat-form-field-appearance-legacy .mat-hint {
  color: #0e0d26;
}
.bigw-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(219, 219, 222, 0.42);
}
.bigw-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(219, 219, 222, 0.42) 0%, rgba(219, 219, 222, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bigw-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(219, 219, 222, 0.42);
}
.bigw-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(219, 219, 222, 0.42) 0%, rgba(219, 219, 222, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.bigw-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(14, 13, 38, 0.04);
}
.bigw-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(14, 13, 38, 0.02);
}
.bigw-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(219, 219, 222, 0.42);
}
.bigw-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: #dbdbde;
}
.bigw-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.bigw-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(219, 219, 222, 0.12);
}
.bigw-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(219, 219, 222, 0.87);
}
.bigw-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #002a78;
}
.bigw-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #0046c8;
}
.bigw-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #0046c8;
}
.bigw-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #0046c8;
}
.bigw-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: #dbdbde;
}
.bigw-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(219, 219, 222, 0.06);
}
.bigw-theme .mat-icon.mat-primary {
  color: #002a78;
}
.bigw-theme .mat-icon.mat-accent {
  color: #0046c8;
}
.bigw-theme .mat-icon.mat-warn {
  color: #0046c8;
}
.bigw-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: #0e0d26;
}
.bigw-theme .mat-input-element:disabled,
.bigw-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: #dbdbde;
}
.bigw-theme .mat-input-element {
  caret-color: #002a78;
}
.bigw-theme .mat-input-element::placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.bigw-theme .mat-input-element::-moz-placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.bigw-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.bigw-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.bigw-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #0046c8;
}
.bigw-theme .mat-form-field.mat-warn .mat-input-element,
.bigw-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #0046c8;
}
.bigw-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #0046c8;
}
.bigw-theme .mat-list-base .mat-list-item {
  color: #0e0d26;
}
.bigw-theme .mat-list-base .mat-list-option {
  color: #0e0d26;
}
.bigw-theme .mat-list-base .mat-subheader {
  color: #0e0d26;
}
.bigw-theme .mat-list-base .mat-list-item-disabled {
  background-color: white;
  color: #dbdbde;
}
.bigw-theme .mat-list-option:hover, .bigw-theme .mat-list-option:focus,
.bigw-theme .mat-nav-list .mat-list-item:hover,
.bigw-theme .mat-nav-list .mat-list-item:focus,
.bigw-theme .mat-action-list .mat-list-item:hover,
.bigw-theme .mat-action-list .mat-list-item:focus {
  background: white;
}
.bigw-theme .mat-list-single-selected-option, .bigw-theme .mat-list-single-selected-option:hover, .bigw-theme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.bigw-theme .mat-menu-panel {
  background: white;
}
.bigw-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-menu-item {
  background: transparent;
  color: #0e0d26;
}
.bigw-theme .mat-menu-item[disabled],
.bigw-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.bigw-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: #dbdbde;
}
.bigw-theme .mat-menu-item .mat-icon-no-color,
.bigw-theme .mat-menu-submenu-icon {
  color: #0e0d26;
}
.bigw-theme .mat-menu-item:hover:not([disabled]),
.bigw-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.bigw-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.bigw-theme .mat-menu-item-highlighted:not([disabled]) {
  background: white;
}
.bigw-theme .mat-paginator {
  background: white;
}
.bigw-theme .mat-paginator,
.bigw-theme .mat-paginator-page-size .mat-select-trigger {
  color: #0e0d26;
}
.bigw-theme .mat-paginator-decrement,
.bigw-theme .mat-paginator-increment {
  border-top: 2px solid #0e0d26;
  border-right: 2px solid #0e0d26;
}
.bigw-theme .mat-paginator-first,
.bigw-theme .mat-paginator-last {
  border-top: 2px solid #0e0d26;
}
.bigw-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.bigw-theme .mat-icon-button[disabled] .mat-paginator-increment,
.bigw-theme .mat-icon-button[disabled] .mat-paginator-first,
.bigw-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: #dbdbde;
}
.bigw-theme .mat-progress-bar-background {
  fill: #bfcadd;
}
.bigw-theme .mat-progress-bar-buffer {
  background-color: #bfcadd;
}
.bigw-theme .mat-progress-bar-fill::after {
  background-color: #002a78;
}
.bigw-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #bfd1f1;
}
.bigw-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #bfd1f1;
}
.bigw-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #0046c8;
}
.bigw-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #bfd1f1;
}
.bigw-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #bfd1f1;
}
.bigw-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #0046c8;
}
.bigw-theme .mat-progress-spinner circle, .bigw-theme .mat-spinner circle {
  stroke: #002a78;
}
.bigw-theme .mat-progress-spinner.mat-accent circle, .bigw-theme .mat-spinner.mat-accent circle {
  stroke: #0046c8;
}
.bigw-theme .mat-progress-spinner.mat-warn circle, .bigw-theme .mat-spinner.mat-warn circle {
  stroke: #0046c8;
}
.bigw-theme .mat-radio-outer-circle {
  border-color: #0e0d26;
}
.bigw-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #002a78;
}
.bigw-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.bigw-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bigw-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .bigw-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #002a78;
}
.bigw-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0046c8;
}
.bigw-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.bigw-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bigw-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .bigw-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #0046c8;
}
.bigw-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0046c8;
}
.bigw-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.bigw-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .bigw-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .bigw-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #0046c8;
}
.bigw-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.bigw-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: #dbdbde;
}
.bigw-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.bigw-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: #dbdbde;
}
.bigw-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: #dbdbde;
}
.bigw-theme .mat-radio-button .mat-ripple-element {
  background-color: #0E0D26;
}
.bigw-theme .mat-select-value {
  color: #0e0d26;
}
.bigw-theme .mat-select-placeholder {
  color: rgba(14, 13, 38, 0.42);
}
.bigw-theme .mat-select-disabled .mat-select-value {
  color: #dbdbde;
}
.bigw-theme .mat-select-arrow {
  color: #0e0d26;
}
.bigw-theme .mat-select-panel {
  background: white;
}
.bigw-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(14, 13, 38, 0.2), 0px 4px 5px 0px rgba(14, 13, 38, 0.14), 0px 1px 10px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.bigw-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #002a78;
}
.bigw-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #0046c8;
}
.bigw-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #0046c8;
}
.bigw-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #0046c8;
}
.bigw-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: #dbdbde;
}
.bigw-theme .mat-drawer-container {
  background-color: white;
  color: #0e0d26;
}
.bigw-theme .mat-drawer {
  background-color: white;
  color: #0e0d26;
}
.bigw-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.bigw-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(14, 13, 38, 0.2), 0px 16px 24px 2px rgba(14, 13, 38, 0.14), 0px 6px 30px 5px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-drawer-side {
  border-right: solid 1px #dbdbde;
}
.bigw-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px #dbdbde;
  border-right: none;
}
.bigw-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px #dbdbde;
  border-right: none;
}
.bigw-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px #dbdbde;
}
.bigw-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.bigw-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0046c8;
}
.bigw-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 70, 200, 0.54);
}
.bigw-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #0046c8;
}
.bigw-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #002a78;
}
.bigw-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 42, 120, 0.54);
}
.bigw-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #002a78;
}
.bigw-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #0046c8;
}
.bigw-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 70, 200, 0.54);
}
.bigw-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #0046c8;
}
.bigw-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: #0e0d26;
}
.bigw-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(14, 13, 38, 0.2), 0px 1px 1px 0px rgba(14, 13, 38, 0.14), 0px 1px 3px 0px rgba(14, 13, 38, 0.12);
  background-color: #fafafa;
}
.bigw-theme .mat-slide-toggle-bar {
  background-color: #dbdbde;
}
.bigw-theme .mat-slider-track-background {
  background-color: #0e0d26;
}
.bigw-theme .mat-slider.mat-primary .mat-slider-track-fill,
.bigw-theme .mat-slider.mat-primary .mat-slider-thumb,
.bigw-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #002a78;
}
.bigw-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.bigw-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 42, 120, 0.2);
}
.bigw-theme .mat-slider.mat-accent .mat-slider-track-fill,
.bigw-theme .mat-slider.mat-accent .mat-slider-thumb,
.bigw-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #0046c8;
}
.bigw-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: #0046c8;
}
.bigw-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(0, 70, 200, 0.2);
}
.bigw-theme .mat-slider.mat-warn .mat-slider-track-fill,
.bigw-theme .mat-slider.mat-warn .mat-slider-thumb,
.bigw-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #0046c8;
}
.bigw-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.bigw-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(0, 70, 200, 0.2);
}
.bigw-theme .mat-slider:hover .mat-slider-track-background,
.bigw-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: #0e0d26;
}
.bigw-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.bigw-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.bigw-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: #0e0d26;
}
.bigw-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: #0e0d26;
}
.bigw-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.bigw-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: #0e0d26;
}
.bigw-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.bigw-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: #0e0d26;
}
.bigw-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: #0e0d26;
  background-color: transparent;
}
.bigw-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .bigw-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: #0e0d26;
}
.bigw-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .bigw-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: #0e0d26;
}
.bigw-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(14, 13, 38, 0.7);
}
.bigw-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(14, 13, 38, 0.7), rgba(14, 13, 38, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(14, 13, 38, 0.7), rgba(14, 13, 38, 0.7) 2px, transparent 0, transparent);
}
.bigw-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(14, 13, 38, 0.7), rgba(14, 13, 38, 0.7) 2px, transparent 0, transparent);
}
.bigw-theme .mat-step-header.cdk-keyboard-focused, .bigw-theme .mat-step-header.cdk-program-focused, .bigw-theme .mat-step-header:hover:not([aria-disabled]), .bigw-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: white;
}
.bigw-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .bigw-theme .mat-step-header:hover {
    background: none;
  }
}
.bigw-theme .mat-step-header .mat-step-label,
.bigw-theme .mat-step-header .mat-step-optional {
  color: #0e0d26;
}
.bigw-theme .mat-step-header .mat-step-icon {
  background-color: #0e0d26;
  color: white;
}
.bigw-theme .mat-step-header .mat-step-icon-selected,
.bigw-theme .mat-step-header .mat-step-icon-state-done,
.bigw-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #002a78;
  color: white;
}
.bigw-theme .mat-step-header.mat-accent .mat-step-icon {
  color: #0046c8;
}
.bigw-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.bigw-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.bigw-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #0046c8;
  color: #0046c8;
}
.bigw-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.bigw-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.bigw-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.bigw-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #0046c8;
  color: white;
}
.bigw-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #0046c8;
}
.bigw-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: #0e0d26;
}
.bigw-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #0046c8;
}
.bigw-theme .mat-stepper-horizontal, .bigw-theme .mat-stepper-vertical {
  background-color: white;
}
.bigw-theme .mat-stepper-vertical-line::before {
  border-left-color: #dbdbde;
}
.bigw-theme .mat-horizontal-stepper-header::before,
.bigw-theme .mat-horizontal-stepper-header::after,
.bigw-theme .mat-stepper-horizontal-line {
  border-top-color: #dbdbde;
}
.bigw-theme .mat-sort-header-arrow {
  color: #0e0d26;
}
.bigw-theme .mat-tab-nav-bar,
.bigw-theme .mat-tab-header {
  border-bottom: 1px solid #dbdbde;
}
.bigw-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.bigw-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid #dbdbde;
  border-bottom: none;
}
.bigw-theme .mat-tab-label, .bigw-theme .mat-tab-link {
  color: #0e0d26;
}
.bigw-theme .mat-tab-label.mat-tab-disabled, .bigw-theme .mat-tab-link.mat-tab-disabled {
  color: #dbdbde;
}
.bigw-theme .mat-tab-header-pagination-chevron {
  border-color: #0e0d26;
}
.bigw-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #dbdbde;
}
.bigw-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.bigw-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.bigw-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 42, 120, 0.3);
}
.bigw-theme .mat-tab-group.mat-primary .mat-ink-bar, .bigw-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #002a78;
}
.bigw-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bigw-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .bigw-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .bigw-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bigw-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 70, 200, 0.3);
}
.bigw-theme .mat-tab-group.mat-accent .mat-ink-bar, .bigw-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #0046c8;
}
.bigw-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bigw-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .bigw-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .bigw-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: #0046c8;
}
.bigw-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 70, 200, 0.3);
}
.bigw-theme .mat-tab-group.mat-warn .mat-ink-bar, .bigw-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #0046c8;
}
.bigw-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bigw-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .bigw-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .bigw-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.bigw-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 42, 120, 0.3);
}
.bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #002a78;
}
.bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bigw-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.bigw-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bigw-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 70, 200, 0.3);
}
.bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #0046c8;
}
.bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: #0046c8;
}
.bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 70, 200, 0.4);
}
.bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: #0046c8;
}
.bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #0046c8;
  opacity: 0.4;
}
.bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bigw-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.bigw-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: #0046c8;
  opacity: 0.12;
}
.bigw-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .bigw-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.bigw-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(0, 70, 200, 0.3);
}
.bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #0046c8;
}
.bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bigw-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.bigw-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.bigw-theme .mat-toolbar {
  background: white;
  color: #0e0d26;
}
.bigw-theme .mat-toolbar.mat-primary {
  background: #002a78;
  color: white;
}
.bigw-theme .mat-toolbar.mat-accent {
  background: #0046c8;
  color: #0046c8;
}
.bigw-theme .mat-toolbar.mat-warn {
  background: #0046c8;
  color: white;
}
.bigw-theme .mat-toolbar .mat-form-field-underline,
.bigw-theme .mat-toolbar .mat-form-field-ripple,
.bigw-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.bigw-theme .mat-toolbar .mat-form-field-label,
.bigw-theme .mat-toolbar .mat-focused .mat-form-field-label,
.bigw-theme .mat-toolbar .mat-select-value,
.bigw-theme .mat-toolbar .mat-select-arrow,
.bigw-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.bigw-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.bigw-theme .mat-tree {
  background: white;
}
.bigw-theme .mat-tree-node,
.bigw-theme .mat-nested-tree-node {
  color: #0e0d26;
}
.bigw-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(14, 13, 38, 0.2), 0px 6px 10px 0px rgba(14, 13, 38, 0.14), 0px 1px 18px 0px rgba(14, 13, 38, 0.12);
}
.bigw-theme .mat-simple-snackbar-action {
  color: #0046c8;
}

html .mat-ink-bar,
html .mat-tab-label,
html .mat-tab-link {
  --color: #0E0D26;
}
html .mat-tab-label,
html .mat-tab-link {
  color: var(--color) !important;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  height: 3.5rem;
  letter-spacing: 0.006rem;
  min-width: auto;
  opacity: 1;
}
html .mat-tab-label:focus-visible,
html .mat-tab-link:focus-visible {
  background-color: #F3F3F4 !important;
}
html .mat-tab-label:focus-visible::before,
html .mat-tab-link:focus-visible::before {
  border: 0.125rem dotted #000;
  z-index: 1;
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
}
html .mat-tab-label:hover,
html .mat-tab-link:hover {
  background-color: #F3F3F4 !important;
}
html .mat-ink-bar {
  background-color: var(--color) !important;
  height: 0.25rem;
}
html .mat-tab-label-active,
html .mat-tab-link-active {
  --color: #0E0D26;
  font-weight: 700;
}
html .woolworths-theme .mat-ink-bar {
  --color: #178841;
}
html .everyday-theme .mat-ink-bar {
  --color: #D44500;
}
html .bigw-theme .mat-ink-bar {
  --color: #0046C8;
}