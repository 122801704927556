@use '~@woolworthslimited/core-tokens/dist/core/scss/tokens.vars' as core;
@use 'tokens';
@use '../utils';
@use 'include-media' as im;
@use './shared' as ls;
@use 'sass:map';

@mixin side-nav {
  $header-offset: calc(ls.$header-height-handset + ls.$nav-toggle-height);

  --padding: #{tokens.$space-xl} #{tokens.$space-md};
  --position: fixed;

  background-color: core.$color-interface-background-primary;
  display: none;
  inset: utils.rem($header-offset) 0 0 0;
  overflow-y: auto;
  padding: var(--padding);
  position: var(--position);
  z-index: tokens.$zindex-side-nav;

  @include im.media('>=#{ls.$side-nav-layout-breakpoint}') {
    --padding: #{tokens.$space-lg} #{tokens.$space-lg} #{tokens.$space-lg} #{tokens.$space-xs};
    --position: static;

    display: block;
  }

  &.show {
    display: block;
  }
}

@mixin foundation-responsive-spacer {
  --margin: #{tokens.$space-7xl};

  margin-bottom: var(--margin);

  @include im.media('>=#{map.get(tokens.$breakpoints, "sm") }') {
    --margin: #{tokens.$space-8xl};
  }
}

@mixin flex-center($size: tokens.$space-xs) {
  --gap: #{$size};

  align-items: center;
  display: flex;
  gap: var(--gap);
}
