@use '~@woolworthslimited/core-tokens/dist/core/scss/tokens.vars' as core;
@use 'layout/shared' as ls;
@use 'include-media' as im;

$max-width-breakpoints: (
  core.$breakpoint-lg: core.$grid-fixed-width-lg,
  core.$breakpoint-xl: core.$grid-fixed-width-xl,
  core.$breakpoint-xxl: core.$grid-fixed-width-xxl,
);

// Implements the "margins" and "fixed width" concerns of the grid
.container {
  --max-width: none;
  --padding: #{core.$grid-margin-compact};

  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-width);
  padding-left: var(--padding);
  padding-right: var(--padding);
  width: 100%;

  @each $breakpoint, $margin in ls.$margin-breakpoints {
    @include im.media('>=#{$breakpoint}') {
      --padding: #{$margin};
    }
  }

  @each $breakpoint, $max-width in $max-width-breakpoints {
    @include im.media('>=#{$breakpoint}') {
      --max-width: #{$max-width};
    }
  }
}
