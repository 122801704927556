@use 'sass:math';
@use 'shared';

/* stylelint-disable scss/at-function-pattern */
@function _pow($x, $n) {
  $ret: 1;

  /* stylelint-disable prettier/prettier */
  @if $n >= 0 {
    @for $i from 1 through $n {
      $ret: $ret * $x;
    }
  }
  /* stylelint-enable prettier/prettier */

  @else {
    @for $i from $n to 0 {
      $ret: $ret / $x;
    }
  }

  @return $ret;
}

@function _to-fixed($float, $digits: 3) {
  $sass-precision: 5;

  @if $digits > $sass-precision {
    @warn "Sass sets default precision to #{$sass-precision} digits, and there is no way to change that for now."
      "The returned number will have #{$sass-precision} digits, even if you asked for `#{$digits}`."
      "See https://github.com/sass/sass/issues/1122 for further informations.";
  }

  $pow: _pow(10, $digits);

  @return math.div(round($float * $pow), $pow);
}
/* stylelint-enable */

@function rem($px: shared.$type-font-size-default-raw) {
  @return _to-fixed(math.div($px, shared.$type-font-size-default-raw) * 1rem);
}

// https://cloudfour.com/thinks/the-ems-have-it-proportional-media-queries-ftw/
@function breakpoint($px: 16) {
  @return _to-fixed(math.div($px, 16) * 1em);
}

@function line-height($line-height, $font-size) {
  $unitless-font-size: math.div($font-size, 1rem) * shared.$type-font-size-default-raw;
  $unitless-line-height: math.div($line-height, $unitless-font-size);

  @return _to-fixed($unitless-line-height);
}
