@use '~@woolworthslimited/core-tokens/dist/core/scss/tokens.vars' as core;
@use '~@woolworthslimited/core-styles-typography/dist/mixins/core' as core-type;
@use 'tokens';
@use 'mixins';
@use 'utils';

html {
  @include core-type.setup-type;

  color: tokens.$color-type-default;
  scroll-behavior: smooth; // TODO: https://woolworthsdigital.atlassian.net/browse/NGDEDS-533

  // TODO: Set up autoprefixer
  /* stylelint-disable property-no-vendor-prefix */
  -moz-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  /* stylelint-enable */
  text-size-adjust: none;
}

iframe {
  border: none;
  height: 100vh;
  width: 100%;
}

body {
  background-color: core.$color-interface-background-primary;

  // Prevent horizontal scrolling on the viewport
  overflow-x: hidden;
  position: relative;
}

/*
 * Apply all global styles to headings (further extended in
 * 'typography/_headings.scss).
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: tokens.$color-type-heading;
}

/*
 * Reinstate correct pointer from "The New CSS Reset".
 */
a,
button {
  cursor: pointer;
}

/*
 * Apply a more readable underline for links and set underline.
 */
a {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}

/*
 * Remove underline from navigational links.
 */
nav a {
  text-decoration: none;
}

/*
 * Apply a readable line length to all paragraphs.
 */
p {
  max-width: tokens.$type-max-line-length;
}

/**
 * Avoid 300ms click delay on touch devices that support the `touch-action` CSS
 * property.
 *
 * @credit
 * - http://caniuse.com/#feat=css-touch-action
 * - http://patrickhlauke.github.io/touch/tests/results/#suppressing-300ms-delay
 * - https://adactio.com/journal/10019
 * - https://developers.google.com/web/updates/2013/12/300ms-tap-delay-gone-away
 */
/* stylelint-disable-next-line no-descending-specificity */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex]:not([tabindex='-1']) {
  touch-action: manipulation;
}

/*
 * Apply a global focus ring.
 * TODO: Work with design to get focus ring specs
 */
*:focus {
  @include mixins.focus-ring;
}

nav {
  a:focus,
  button:focus {
    @include mixins.focus-ring;
  }
}

select:focus,
input:focus {
  @include mixins.focus-ring;
}

/*
 * Maintain intrinsic aspect ratios when `max-width` is applied.
 */
img {
  height: auto;
}

/*
 * Remove the default `300px` width, especially as audio files are unlikely to
 * come with a `width` attribute.
 *
 * @credit https://github.com/jensimmons/cssremedy
*/
audio {
  width: 100%;
}

/**
 * Indicates that a label will shift focus to its associated control.
 */
label[for] {
  cursor: pointer;
}

/**
 * Prevents an unwanted focus outline from appearing around elements that might
 * still respond to pointer events.
 */
[tabindex='-1']:focus {
  outline: none !important;
}

/**
 * Remove the outline in cases where the UA determines via heuristics that the
 * focus should not be visible. This is mainly for ensuring that a focus
 * ring does not render for mouse pointers.
 *
 * NOTE: `!important` is fine here as we always want this to apply.
 */

*:focus:not(:focus-visible) {
  box-shadow: none !important;
  outline: none !important;
}
