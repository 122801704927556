@font-face {
  font-family: 'Fresh Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../resources/fonts/FreshSans/FreshSans-Light.woff2') format('woff2'),
    url('../resources/fonts/FreshSans/FreshSans-Light.woff') format('woff'),
    url('../resources/fonts/FreshSans/FreshSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Fresh Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../resources/fonts/FreshSans/FreshSans-LightItalic.woff2') format('woff2'),
    url('../resources/fonts/FreshSans/FreshSans-LightItalic.woff') format('woff'),
    url('../resources/fonts/FreshSans/FreshSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Fresh Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../resources/fonts/FreshSans/FreshSans-Medium.woff2') format('woff2'),
    url('../resources/fonts/FreshSans/FreshSans-Medium.woff') format('woff'),
    url('../resources/fonts/FreshSans/FreshSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Fresh Sans';
  font-style: normal;
  font-weight: normal;
  src: url('../resources/fonts/FreshSans/FreshSans-Regular.woff2') format('woff2'),
    url('../resources/fonts/FreshSans/FreshSans-Regular.woff') format('woff'),
    url('../resources/fonts/FreshSans/FreshSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Fresh Sans';
  font-style: italic;
  font-weight: normal;
  src: url('../resources/fonts/FreshSans/FreshSans-RegularItalic.woff2') format('woff2'),
    url('../resources/fonts/FreshSans/FreshSans-RegularItalic.woff') format('woff'),
    url('../resources/fonts/FreshSans/FreshSans-RegularItalic.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url('../resources/fonts/Roboto/Roboto-Regular-400.woff2') format('woff2'),
    url('../resources/fonts/Roboto/Roboto-Regular-400.woff') format('woff'),
    url('../resources/fonts/Roboto/Roboto-Regular-400.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  src: url('../resources/fonts/Roboto/Roboto-RegularItalic-400.woff2') format('woff2'),
    url('../resources/fonts/Roboto/Roboto-RegularItalic-400.woff') format('woff'),
    url('../resources/fonts/Roboto/Roboto-RegularItalic-400.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url('../resources/fonts/Roboto/Roboto-Medium-500.woff2') format('woff2'),
    url('../resources/fonts/Roboto/Roboto-Medium-500.woff') format('woff'),
    url('../resources/fonts/Roboto/Roboto-Medium-500.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  src: url('../resources/fonts/Roboto/Roboto-MediumItalic-500.woff2') format('woff2'),
    url('../resources/fonts/Roboto/Roboto-MediumItalic-500.woff') format('woff'),
    url('../resources/fonts/Roboto/Roboto-MediumItalic-500.ttf') format('truetype');
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url('../resources/fonts/Roboto/Roboto-Bold-700.woff2') format('woff2'),
    url('../resources/fonts/Roboto/Roboto-Bold-700.woff') format('woff'),
    url('../resources/fonts/Roboto/Roboto-Bold-700.ttf') format('truetype');
}

@font-face {
  font-family: 'GT Big Walsheim';
  font-style: normal;
  font-weight: 400;
  src: url('../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Regular.woff2') format('woff2'),
    url('../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Regular.woff') format('woff'),
    url('../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'GT Big Walsheim';
  font-style: normal;
  font-weight: 300;
  src: url('../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Thin.woff2') format('woff2'),
    url('../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Thin.woff') format('woff'),
    url('../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'GT Big Walsheim';
  font-style: normal;
  font-weight: 700;
  src: url('../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Bold.woff2') format('woff2'),
    url('../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Bold.woff') format('woff'),
    url('../resources/fonts/GTBigWalsheim/GT-Big-Walsheim-Bold.ttf') format('truetype');
}

@font-face {
  font-family: BigDays;
  font-style: normal;
  font-weight: 400;
  src: url('../resources/fonts/BigDays/BigDays-Regular.woff2') format('woff2'),
    url('../resources/fonts/BigDays/BigDays-Regular.woff') format('woff');
}
